import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    listData: { progress_bar_text: string, progress_bar_stage: string }[]
    activeStep: string;
    insurancePlan: string;
    inputStateValue: string;
    providerType: string | null;
    validateToConfirmPage: boolean;
    AssembleCareTeam: boolean;
    clickedRadio: string,
    openDropDown: boolean,
    dropDownValue: string,
}

interface SS {
    id: any;
}

export default class CareTeamInformationController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            inputStateValue: "",
            validateToConfirmPage: false,
            AssembleCareTeam: false,
            openDropDown: false,
            providerType: "",
            dropDownValue: "",
            listData: [
                { progress_bar_text: 'About You', progress_bar_stage: '1' },
                { progress_bar_text: 'Verify Insurance', progress_bar_stage: '2' },
                { progress_bar_text: 'Choose Provider', progress_bar_stage: '3' }
            ],
            activeStep: 'Choose Provider',
            insurancePlan: "Original Medicare",
            clickedRadio: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleStateInput = (e: any) => { this.setState({ inputStateValue: e.target.value }, () => { this.handleValidateButton() }) };

    handleProviderType = (value: string) => { this.setState({ providerType: value, openDropDown: false, dropDownValue: value }, () => { this.handleValidateButton() }) };

    handleRadioOption = (value: string) => { this.setState({ clickedRadio: value }, () => { this.handleValidateButton() }) }

    handleValidateButton = () => {
        if (this.state.inputStateValue === "" || this.state.inputStateValue === null || this.state.providerType === "" || this.state.providerType === null || this.state.clickedRadio === "") {
            this.setState({ validateToConfirmPage: false });
        }
        else {
            this.setState({ validateToConfirmPage: true })
        }
    }

    AssembleCareTeam = () => {
        if (this.state.validateToConfirmPage === true) {
            this.setState({ AssembleCareTeam: true })
        }
    }

    goBack = () => { this.props.navigation.goBack() };
    // Customizable Area End

}