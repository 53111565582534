// Customizable Area Start
import { createRef, RefObject } from 'react';
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");



export interface Props {
  classes: Object;
  navigation?: any;
  id: string;
}
interface ContactType1 {
  phone: boolean;
  email: boolean;
  sms: boolean;
}
interface AllResponseJsonData1 {
    
        form_header: string,
        form_description: string,
        contact_method: any,
        relationship_to_patient: any,
        reason_for_signing_up: any,
        button_text: string,
        mobile_number: boolean,
        email: boolean,
        first_name: boolean,
        last_name: boolean,
        suffix: boolean,
        date_of_birth: boolean,
        state: boolean,
        created_at: string,
        updated_at: string,
        stage_type: string,
        help_footer: boolean
        progress_bars_attributes:any
        
}
interface ResponsePage {
  data : {
  id: string | number,
  type: string,
  attributes:{
  reason_for_signing_up: string,
  button_text: string,
  mobile_number: boolean,
  email: boolean,
  first_name: boolean,
  last_name: boolean,
  suffix: boolean,
  form_header: string,
  form_description: string,
  contact_method: string[],
  relationship_to_patient: string,
  date_of_birth: boolean,
  state: boolean,
  created_at: string,
  updated_at: string,
  stage_type: string,
  help_footer: boolean,
  progress_bars_attributes:[
      {progress_bar_text:string,
      progress_bar_stage:string|number
      }
  ],

  }
}
}


interface S {
    landline: boolean,
    patientReasons:string;
    selectedMethods:any;
    phoneCheckbox:boolean;
    landline_no: string,
    consentChecked:boolean,
    authEmail:string,
    patientRelation:string,
    emailCheckbox:boolean;
    allResponseJsondata:AllResponseJsonData1,
    button_text: string,
    relationshipList: string[],
    whyDataList: string[],
    listData: any,
    email: boolean,
    mobile_number: boolean,
    first_name: boolean,
    last_name: boolean,
    footer: boolean
    responseData: "",
    responseDataId:string | number,
    open:boolean,
    agreeChecked:boolean,
    role:string,
    apiPageType:string,
    modalOpen:boolean,

    consentPatient: boolean,
    termsConditions: boolean,
    activeStep: string,
    contact_type: ContactType1,
    whyData: boolean,
    selectedValue: string | [];
    form_description: string,
    relationship: boolean,
    selectedWhyData: string,
    selectedRelationship: string,
    
    smsCheckbox:boolean
}

interface SS {
    id: string;
}

export default class AuthorizedRepresentativeController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAuthorisedRepresentativeCallId: string = "";
    whyRef: RefObject<HTMLDivElement> | null;
    relationshipRef: RefObject<HTMLDivElement> | null;
    patientPostApiCallID: string = "";
    postAuthorisedApiCallId:string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess)
        ];

        this.state = {
            authEmail:"",
            selectedValue: [],
            
            modalOpen:false,
            role:"",
            agreeChecked:false,
            consentChecked:false,
            apiPageType:"",
            selectedMethods:[],
            patientRelation:"",
            patientReasons:"",
            phoneCheckbox:false,
            smsCheckbox:false,
            emailCheckbox:false,
            
          
            open:false,
            email: false,
            activeStep: '',
            mobile_number: false,
            contact_type: {
                phone: false,
                email: false,
                sms: false
            },
            whyData: false,
            relationship: false,
            selectedRelationship: '',
            selectedWhyData: '',
            termsConditions: false,
            consentPatient: false,
            allResponseJsondata:{
              button_text: "",
              mobile_number: false,
              email: false,
              first_name: false,
              last_name: false,
              suffix: false,
              form_header: "",
              form_description: "",
              contact_method: [],
              relationship_to_patient: [],
              reason_for_signing_up: [],
              date_of_birth: false,
              state: false,
              created_at: '',
              updated_at: '',
              stage_type: '',
              help_footer: false,
              progress_bars_attributes: []
          },
            footer: false,
            form_description: '',
            landline: false,
            first_name:false,
            last_name: false,
            landline_no: '',
            button_text: '',
            relationshipList: [],
            whyDataList: [],
            listData: [],
          
            responseData : "",
            responseDataId:"",
        };
        
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      this.relationshipRef = createRef();
      this.whyRef = createRef();
    }
    commonApiCalls = (type: string, urlEndPoint: string, method: string ) => {
     
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
    };
      if (type === 'get') {
          this.getAuthorisedRepresentativeCallId = requestMessage.messageId;
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          urlEndPoint
      );
     
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
   
    handleApiGetResponse = (responseJson: ResponsePage) => {
      const {stage_type} = responseJson.data.attributes;
        this.setState({
          allResponseJsondata:responseJson.data.attributes,
           activeStep: stage_type,
       })
    }
    handleCheckboxChange = (item:any) => (event:any) => {
      const isChecked = event.target.checked;
      const { selectedMethods } = this.state;
  
      if (isChecked) {
        if (!selectedMethods.includes(item)) {
          this.setState({
            selectedMethods: [...selectedMethods, item],
          });
        }
      } else {
        this.setState({
          selectedMethods: selectedMethods.filter((method:any) => method !== item),
        });
      }
    };

   

   
    async componentDidMount(): Promise<void> {
        this.postTherapySignupApi();
        this.commonApiCalls('get', configJSON.representativeEndPoint, configJSON.validationApiMethodType);
    }

    handleResponseForPatient = async (from: string, message: Message) => {
        if (
          this.postAuthorisedApiCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if(responseJson){
              this.setState({responseDataId:responseJson.data.id} , () => {
                this.handleNavigationAuthUpdate('AuthorizedRepresentativeUpdate',this.props,this.state.responseDataId)            
      
              })
          }
        }
      };
      handleAgreeChange = (value: boolean) => {
        this.setState({ agreeChecked: value });
      };
      handleConsentChange = (value: boolean) => {
        this.setState({ consentChecked: value });
      };
      handleNavigateTermCondition = (pageType:string,userRole:string) => {
        this.setState({role:userRole,apiPageType:pageType,modalOpen:!this.state.modalOpen})
    }
    handleNavigationAuthUpdate = (pathname: string, props: unknown, param?: any) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), pathname);
      message.addData(getName(MessageEnum.NavigationPropsMessage), props);
      param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
      runEngine.sendMessage(message.id, message)
    };
    postTherapySignupApi = async () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
        
        let data = {
            data:{
                type: "authorized_representatives",
                  attributes: {
                  email: this.state.authEmail,
                  relationship_patient: this.state.patientRelation,
                  reason_for_signing_up: this.state.patientReasons,
                  contact_method: this.state.selectedMethods
                }}
              
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postAuthorisedApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.authorisedApiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      async receive(from: string, message: Message) {
        if (message) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            if (apiRequestCallId && responseJson) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.getAuthorisedRepresentativeCallId) {
                        this?.handleApiGetResponse(responseJson);
                    }
                } 
            }
        }
        this.handleResponseForPatient(from, message);
    }

}

// Customizable Area End