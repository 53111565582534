export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const ProfileImage = require("../assets/ProfileImage.png").default;
export const Card_number_icon = require("../assets/Card_number_icon.png").default;
export const Date_icon = require("../assets/Date_icon.png").default;
export const LocationIcon = require("../assets/LocationIcon.png").default;
export const Security_code_icon = require("../assets/Security_code_icon.png").default;
export const Submit_details_icon = require("../assets/Submit_details_icon.png").default;
export const Submitted_details_icon = require("../assets/Submitted_details_icon.png").default;
export const AmericanExpressIcon = require("../assets/AmericanExpressIcon.png").default;
export const ApplePay = require("../assets/ApplePay.png").default;
export const Maestro = require("../assets/Maestro.png").default;
export const MasterCard = require("../assets/MasterCard.png").default;
export const VisaIcon = require("../assets/VisaIcon.png").default;
export const DiscoverIcon = require("../assets/DiscoverIcon.png").default;


