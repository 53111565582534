// Customizable Area Start
import { createRef, RefObject } from 'react';
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
    id: string;
    classes: Object;
    navigation?: any;
}

interface ResponsePage1 {
    data : {
    id: string | number,
    type: string,
    attributes:{
    form_header: string,
    form_description: string,
    contact_method: string,
    relationship_to_patient: string,
    reason_for_signing_up: string,
    button_text: string,
    mobile_number: boolean,
    email: boolean,
    first_name: boolean,
    last_name: boolean,
    suffix: boolean,
    date_of_birth: boolean,
    state: boolean,
    created_at: string,
    updated_at: string,
    stage_type: string,
    help_footer: boolean,
    // progress_bars: {progress_bar_text: string, progress_bar_stage: string}[]
    progress_bars_attributes:[
        {progress_bar_text:string,
        progress_bar_stage:string|number
        }
    ],
  
    }
  }
}

interface AllResponseJsonData {
    
        form_header: string,
        form_description: string,
        contact_method: string,
        relationship_to_patient: any,
        reason_for_signing_up: any,
        button_text: string,
        mobile_number: boolean,
        email: boolean,
        first_name: boolean,
        last_name: boolean,
        suffix: boolean,
        date_of_birth: boolean,
        state: boolean,
        created_at: string,
        updated_at: string,
        stage_type: string,
        help_footer: boolean
        // progress_bars: {progress_bar_text: string, progress_bar_stage: string}[]
        // progress_bars_attributes:[
        //     {progress_bar_text:string,
        //     progress_bar_stage:string|number
        //     }
        // ]
        progress_bars_attributes:any
        
}
interface ContactType {
    phone: boolean;
    email: boolean;
    sms: boolean;
}

interface S {
    allResponseJsondata:AllResponseJsonData,
    activeStep: string,
    contact_type: ContactType,
    whyData: boolean,
    relationship: boolean,
    selectedWhyData: string,
    selectedRelationship: string,
    consentPatient: boolean,
    termsConditions: boolean,
    selectedValue: string | [];
    form_description: string,
    landline: boolean,
    landline_no: string,
    landline_number:boolean,
    contact_method: string[],
    button_text: string,
    relationshipList: string[],
    whyDataList: string[],
    listData: any,
    email: boolean,
    mobile_number: boolean,
    first_name: boolean,
    last_name: boolean,
    footer: boolean
    responseData: "",
    open:boolean,
    agreeChecked:boolean,
    role:string,
    apiPageType:string,
    modalOpen:boolean,
    consentChecked:boolean,
    firstName:string;
    lastName:string;
    therapyPhoneNumber:string;
    therapyLadlineNo:string | number
}

interface SS {
    id: string;
}

export default class AuthorizedRepresentativeController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAuthorisedRepresentativeCallId: string = "";
    whyRef: RefObject<HTMLDivElement> | null;
    relationshipRef: RefObject<HTMLDivElement> | null;
    patientPostApiCallID: string = "";
    postAuthoriseApiCallId:string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess)
        ];

        this.state = {
            therapyLadlineNo:"",
            therapyPhoneNumber:"",
            firstName:"",
            lastName:"",
            selectedValue: [],
            consentChecked:false,
            apiPageType:"",
            modalOpen:false,
            role:"",
            agreeChecked:false,
            allResponseJsondata:{
            form_header: "",
            form_description: "",
            contact_method: "",
            relationship_to_patient: [],
            reason_for_signing_up: [],
            button_text: "",
            mobile_number: false,
            email: false,
            first_name: false,
            last_name: false,
            suffix: false,
            date_of_birth: false,
            state: false,
            created_at: '',
            updated_at: '',
            stage_type: '',
            help_footer: false,
            progress_bars_attributes: []
        },
            open:false,
            email: false,
            activeStep: '',
            mobile_number: false,
            contact_type: {
                phone: false,
                email: false,
                sms: false
            },
            whyData: false,
            relationship: false,
            selectedRelationship: '',
            selectedWhyData: '',
            termsConditions: false,
            consentPatient: false,
            // form_header: '',
            form_description: '',
            landline: false,
            first_name:false,
            last_name: false,
            landline_no: '',
            landline_number:false,
            contact_method: [],
            button_text: '',
            relationshipList: [],
            whyDataList: [],
            listData: [],
            footer: false,
            responseData : ""
        };
        this.whyRef = createRef();
        this.relationshipRef = createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (message) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            if (apiRequestCallId && responseJson) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.getAuthorisedRepresentativeCallId) {
                        this.handleApiGetResponse(responseJson);
                    }
                } 
            }
        }
    }

    handleApiGetResponse = (responseJson: ResponsePage1) => {
      const {stage_type} = responseJson.data.attributes;
        this.setState({
          allResponseJsondata:responseJson.data.attributes,
           activeStep: stage_type,
       })
    }

    commonApiCall = (type: string, urlEndPoint: string, method: string ) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (type === 'get') {
            this.getAuthorisedRepresentativeCallId = requestMessage.messageId;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            urlEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    async componentDidMount(){
        this.commonApiCall('get', configJSON.representativeEndPoint, configJSON.validationApiMethodType);
    }

     handleAgreeChange = (value: boolean) => {
        this.setState({ agreeChecked: value });
      };
      handleConsentChange = (value: boolean) => {
        this.setState({ consentChecked: value });
      };
      handleNavigateTermCondition = (pageType:string,userRole:string) => {
        this.setState({role:userRole,apiPageType:pageType,modalOpen:!this.state.modalOpen})
    }
    handleLandlineToggles = () => {
        this.setState((prevState) => ({
          landline_number: !prevState.landline_number,
        }));
      }; ;
    putTherapySignupApi = async (id:number|string) => {
       const {landline_number,firstName,lastName,therapyPhoneNumber,therapyLadlineNo} = this.state
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
        let attributes: any = {
            first_name: firstName,
            last_name: lastName,
            terms_of_use: true,
            help_footer: true,
            landline_status:this.state.landline_number
          };
          
        if (landline_number) {
            attributes.landline_number = therapyLadlineNo;
          }
          else{
            attributes.phone_number = therapyPhoneNumber;
          }
        let data = {
             data: {
                    type: "authorized_representive",
                    attributes: attributes }
            }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postAuthoriseApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/authorized_representatives/${id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }; 
   
}

// Customizable Area End