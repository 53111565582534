// Customizable Area Start
import React from 'react';
import {
  Box,
  styled,
  TextField,
  Divider
} from "@mui/material";
import { HeaderLogoImg, emergency, call} from '../assets';
import { Formik, Form, Field } from "formik";
import PhoneInput from 'react-phone-input-2';

import { ConditionalRenderer, ResolveCondition } from "../../../../blocks/utilities/src/CustomHelpers"
import * as Yup from "yup";
import StepperData from "../../../../components/src/StepperData.web";
import PatientDetailsController, {
  Props,
} from "./PatientDetailsController";
import { setStorageData } from '../../../../framework/src/Utilities';


class PatientDetails extends PatientDetailsController {
  constructor(props: Props) {
    super(props);
  }

  validationSchema = () => {
    return Yup.object().shape({
      phone_number: Yup.string().when([], {
        is: () => this.state.formStats?.phone_number_req === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }),
      email: Yup.string().email('Invalid email address').required("Email is required"), 
    });}

  render() {
    const paramId = this.props.navigation.getParam("navigationBarTitleText");   
    return (
      <StyledWrapperForWellness>
        <section className="about-section">
          <Box className="headerLogo1">
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
          </Box>
          <Divider />
          <Box className="headerStepper">
            <StepperData
              key={`stepper${this.state.formStats.stage_type}`}
              listData={this.state.formStats.progress_bars}
              activeStep={this.state.formStats.stage_type}
            />
          </Box>

          <Formik
            initialValues={{
              phone_number: "",
              landline_number: "",
              email: "",
              phone_checkbox: false,
              email_checkbox: false,
              sms_checkbox: false,
              contact_method: []
            }}
            data-test-id="formikWrapper"
            validationSchema={this.validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setStorageData("values", JSON.stringify(values));
                setStorageData("Param Value", paramId);
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                    <Box className="main-section">    
                       <h2 className="question1">
                    {this.state.formStats.form_header}
                  </h2>
                  <p className="information1">
                    {this.state.formStats.form_description}
                  </p>
                  <Box className="box1">
                  {
                      this.state.formStats.phone_number &&
                    <Box className="div-container">
                      <div className="heading">
                        {this.state.formStats.phone_number_label}
                        {ConditionalRenderer(this.state.formStats.phone_number_req,<span className='required-text'>*</span>,'')}
                      </div>
                      <Box className="inputField">
                        <PhoneInputWrapper>
                          <PhoneInput
                            country={'us'}
                            placeholder='Enter your phone number'
                            value={values.phone_number}
                            data-testid="mobile_number"
                            onChange={(phone) => {
                              setFieldValue('phone_number', phone);
                              this.setState({ therapyPhoneNo: phone }); 
                            }}
                          />
                        </PhoneInputWrapper>
                        {ConditionalRenderer(Boolean(errors.phone_number),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.phone_number),
                                <div className="error">
                                  {errors.phone_number}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                      </Box>
                      <Box className="checkboxContainer">
                        <label style={webstyle.checkboxLabel}>
                          <input
                            type="checkbox"
                            checked={this.state.landline_no}
                            onChange={this.handleLandlineToggles}
                            style={webstyle.checkboxInput}
                            data-test-id="landline"
                          />
                          <span style={webstyle.checkboxCustom1(this.state.landline_no)}>
                            {<span style={webstyle.checkmark1}></span>}
                          </span>
                          <span className="checkboxText">I don't have a mobile - add a landline</span>
                        </label>
                      </Box>
                      {
                        ConditionalRenderer(this.state.landline_no, <Box className="inputField"
                        >
                          <PhoneInputWrapper>
                            <PhoneInput
                              country={'us'}
                              placeholder='Enter your phone number'
                              value={values.landline_number}
                              data-test-id="landline_number"
                              onChange={(landline_no: any) => {
                                setFieldValue('landline_number', landline_no);
                                  this.setState({ therapyLadlineNo: landline_no });
                              }} />
                          </PhoneInputWrapper>
                        </Box>, '')
                      }
                    </Box>
                    }
                       {
                      this.state.formStats.email &&
                    <Box className="div-container">
                      <div className="heading">
                        {this.state.formStats.email_label} 
                       {ConditionalRenderer(this.state.formStats.email_req,<span className='required-text'>*</span>,'')}
                      </div>
                      <Box className="inputField">
                        <Field
                          onChange={(e: any) => {
                            handleChange(e);
                            this.setState({therapyEmail: e.target.value})
                          }}
                          name="email"
                          data-test-id="txtInput2"
                          as={TextField}
                          value={values.email}
                          className="emailField inputFields"
                          placeholder="Enter Email address"
                        />
                        {ConditionalRenderer(Boolean(errors.email),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.email),
                                <div className="error">
                                  {errors.email}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                      </Box>
                    </Box>
                    }
                 


                  {this.state.formStats.contact_method && (
                    <>
                      <div className="heading">
                          Preferred Contact Method{" "}
                          {ConditionalRenderer(this.state.formStats.contact_method_req,<span className='required-text'>*</span>,'')}                          
                          <span className="Select">
                            (select all that apply)
                          </span>
                        </div>
                      <Box className="checkBoxGroup">
                        <label style={webstyle.checkboxLabel} >
                          <input
                            type="checkbox"
                            data-test-id="phone1"
                            onChange={(e: any) => {
                              setFieldValue("phone_checkbox", e.target.checked);
                                this.handleCheckboxChange("phone", e.target.checked);
                            }}
                            name="phone_checkbox"
                            checked={values.phone_checkbox}
                            style={webstyle.checkboxInput}
                          />
                          <span
                            style={webstyle.checkboxCustom1(values.phone_checkbox)}>
                            {<span style={webstyle.checkmark1}></span>}
                          </span>
                          <span className="phoneClass">Phone</span>
                        </label>
                        <label style={webstyle.checkboxLabelEmail}>
                          <input
                            type="checkbox"
                            data-test-id="email1"
                            style={webstyle.checkboxInput}
                            onChange={(e: any) => {
                              setFieldValue("email_checkbox", e.target.checked);
                                this.handleCheckboxChange("email", e.target.checked);
                            }}
                            name="email_checkbox"
                            checked={values.email_checkbox}
                          />
                          <span
                            style={webstyle.checkboxCustom1(values.email_checkbox)}>
                            {<span style={webstyle.checkmark1}></span>}
                          </span>
                          <span className="phoneClass">Email</span>
                        </label>
                        <label style={webstyle.checkboxLabel}>
                          <input
                            type="checkbox"
                            data-test-id="sms1"
                            style={webstyle.checkboxInput}
                            onChange={(e: any) => {
                              setFieldValue("sms_checkbox", e.target.checked);
                                this.handleCheckboxChange("sms", e.target.checked);
                            }}
                            name="sms_checkbox"
                            checked={values.sms_checkbox}
                          />
                          <span
                            style={webstyle.checkboxCustom1(values.sms_checkbox)}>
                            {<span style={webstyle.checkmark1}></span>}
                          </span>
                          <span className="phoneClass">SMS</span>
                        </label>
                      </Box>
                      {ConditionalRenderer(Boolean(errors.contact_method),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.contact_method),
                                <div className="error">
                                  {errors.contact_method}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                    </>
                  )}
                   <Box className="checkboxContainerLast">
                      
                      <label style={webstyle.checkboxLabel1}>
                         <input
                           type="checkbox"
                           style={webstyle.checkboxInput}  
                           name="agree"
                           data-test-id="agreeCheck"
                           checked={this.state.agreeChecked}
                           onChange={(e) =>
                           this.handleAgreeChanges(e.target.checked)
                           }
                         />
                        <span
                          style={webstyle.checkboxCustom1(this.state.agreeChecked)}>
                         {<span style={webstyle.checkmark1}></span>}
                        </span>
                        <p style={webstyle.patientConsent}>
                        I have patient consent to act as their authorized representative
                        </p>
                       </label>
                   </Box>
                   <Box>
                    <button
                     onClick={()=>
                      this.postTherapySignupApi() 
                    }
                      className={`button-continues ${ResolveCondition(
                        this.state.agreeChecked,
                        "active",
                        "inactive"
                      )}`}
                      disabled={!this.state.selectedValue}
                      data-test-id="submitBtn"
                      type="submit"
                    >
                      Continue
                    </button>
                  </Box>
                  <Box>
                    <u className="content">Already have an account? Log in</u>
                  </Box>
                 </Box>
                 
                  
                </Box>
              </Form>
            )}
          </Formik>
          {
              ConditionalRenderer(this.state.formStats.help_footer, <Box  id="footers" className="footers">
              <p className="emergencyIcons">
                <span>
                  <img  className="yellowFilter" src={emergency} alt="emergency1" />
                </span>
                &nbsp; If this is an emergency &nbsp; <u>please call 911</u>
              </p>
              <div id="rightFooter1" className="footerRight1" >
                <span  className="label-700-201"  id="label-need-help1">
                  Need Help?
                </span>
                <button  data-test-id="callBTN1"  className="callButton1">
                  Schedule a call?
                  <span className="callImg1">
                    <img alt="calls" src={call}  />
                  </span>
                </button>
              </div>
            </Box>,<Box className="footers"></Box>)
               
            }

        </section>
      </StyledWrapperForWellness>
    );
  }
}

const StyledWrapperForWellness = styled("div")({
  margin: "0 auto",
  height: "100%",
  width: "100%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .required-text": {
    textFillColor: "#DC2626",
    WebkitTextFillColor: "#DC2626",
    margin: "3px"
  },
  
  "& .emailField .MuiOutlinedInput-root": {
    borderRadius: "24px",
    borderColor: "#E6C79C",
    width: "100%",
    height: "42px",
    maxWidth: "600px", 
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
    "& .MuiInputBase-input": {
      height: "100%",
    },
    "& fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
    },
  },
  "& .MuiFormControl-root.MuiTextField-root.emailField.inputFields": {
    width: "100% !important"
  },
 
  "& .headerStepper": {
    "paddingTop": "32px",
    "justifyContent": "center",
    "display": "flex",
   
  },
  "& .MuiCheckbox-root.Mui-checked svg": {
    color: "#1A4A42",
    borderRadius: "4px",
  },
  "& .MuiCheckbox-root svg": {
    borderRadius: "10px",
  },
  
  "& .header .custom-step-connector1 .MuiStepConnector-root": {
    display: "none",
  },
  "& .PhoneInputInput::placeholder, & .MuiInputBase-input::placeholder": {
    fontSize: "16px",
    fontFamily: "Poppins",
   
  },
  "& .header .custom-step-connector1 .MuiStepper-horizontal": {
    position: "relative",
    justifyContent: "space-between",
   
  },
 
  "& .inputField .inputFields .MuiInputBase-root": {
    width: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px"
  },
  "& .header .custom-step-connector1 .MuiSvgIcon-root": {
    borderRadius: "10px",
  },
  "& .inputField .inputFields .MuiOutlinedInput-root": {
    
    borderColor: "purple",width: "100%",
    maxHeight: "42px",
    borderRadius: "24px",

    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },

    "& fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
    },
  },
  "& .header .custom-step-connector1 .MuiStepLabel-iconContainer::after":
  {
    height: "3px",
    backgroundColor: "#A8A29E",
    content: `""`,
    position: "absolute",
    width: "239px",
    left: "44px",
    top: "10px",
  },

  "& .Select": {
    fontFamily: "Poppins",
    color: "#78716C",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
   
  },

  "& .error": {
    lineHeight:"19.5px",
    color: "#DC2626",
    textAlign: "initial",
    fontWeight:400,
    fontFamily:"Poppins",
    fontSize:"13px",
  },
  
  "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    width: "100%",
  },
  "& .error-container": {
    // minHeight: "20px",
  },
  "& .inputField .inputFields .MuiInputBase-root.MuiOutlinedInput-root":
  {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px",
  },
  "& .italic": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textDecoration: "underline",
    color: "#1A4A42",
    textDecorationColor: "green",
  },
  "& .content": {
    fontWeight: 700,
    color: "#E46760",
    fontSize: "20px",
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    marginTop:"28px",
  },
  "& .div-container": {
    marginBottom: "24px",
  },

  "& .checkBoxGroup": {
    maxWidth: "291px",
    display: "flex",
    alignItems: "center",
    maxHeight: "22px",
    marginTop: "6px",
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "-10px"
  },
  "& .phoneClass": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px"
  },

  "& .checkBoxGroup.active": {
    backgroundColor: "#000000",
  },
  "& .checkboxContainerLast": {
    marginTop: "24px",
    alignItems: "center",
  },
  "& .checkboxContainer": {
    display: "flex",
    alignItems: "center",
    marginBottom:"4px",
    marginTop:"4px"
  },
  "& .upperCheckbox": {
    borderRadius: "20px"
  },
  '& .checkboxText': {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .heading": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "justify",
  },
  "& .callButton1": {
    borderRadius: "16px",
    background: "#ffffff",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    border: "1px solid #E46760",
    padding: "5px 10px",
   
  },
  "& .box1": {
     maxHeight: "340px",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    marginTop: "32px",
    height: "100%",
    width: "100%",
    maxWidth: "600px",
   
  },
  "& .headerLogo1": {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: "auto",
    width: "100%",
    minHeight: "78px",
   
  },
  "& .custom-step-connector1": {
    maxWidth: "600px",
    width: "100%",
  },
  "& .emergencyIcons": {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
   
  },
  "& .header .custom-step-connector1 .MuiStepLabel-labelContainer": {
    flexDirection: "column",
    alignItems: "center",
   
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42;",
  },
 
  "& .label-700-201": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
  },
  "& .main-section": {
    textAlign: "center",
    marginTop: "30px"
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .footerRight1": {
    gap: "12px",
    alignItems: "center",
    display: "flex",
   
  },
 
 
  "& .information1": {
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "32px",
    maxWidth: "600px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  "& .question1": {
    fontWeight: 600,
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    maxWidth: "700px",
    margin: "auto",
    fontFamily: "Raleway",
    
  },
 
  "& .footers": {
    alignItems: "center",
    borderTop: "1px solid #E7E5E4",
    marginTop: "210px",
    padding: "0px 141px",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "96px",
    "@media(max-width:750px)": {
      padding: "0px 0px",
    },
  },
 
 
  "& .button-continues.active": {
    border: "none",
    background: "#E46760",
    cursor: "pointer",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    marginTop: "32px",
   marginBottom:"54px"
  },

  "& .button-continues.inactive": {
    border: "none",
    background: "#D6D3D1",
    cursor: "not-allowed",
    color: "#FFFFFF",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    marginTop: "32px",
    marginBottom:"54px"

  },
  "& .button-continues": {
    marginTop: "32px",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23.48px",
    border: "none",
    maxWidth: "600px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    cursor: "pointer",
    background: "#E46760",
    marginBottom:"54px"

  },
  "& u": {
    color: "#D97706",
  },
  "& .header": {
    height: "auto",
    minHeight: "140px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  "@media screen and (max-width:1200px)": {
    "& .logoImg": {
      marginLeft: "50px",
    },
  },
  "@media screen and (max-width:767px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
  },
});
const PhoneInputWrapper = styled(Box)({
  "& .form-control": {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    paddingLeft: "15px",
    color: "#36403B",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: "24px",
    border: "1px solid #E6C79C"
  },
  "& .form-control::placeholder": {
    fontSize: "15px",
  },
  "& .selected-flag": {
    display: "none"
  },
  "& .flag-dropdown ": {
    border: "none"
  }
});

const webstyle = {
  container: {
    fontFamily: "Arial, sans-serif",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
  },
  checkboxLabel1: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
    width:"675px"
  },
  checkboxLabelEmail: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
    margin: "0px 24px"
  },
  checkboxInput: {
    display: "none",
  },
  patientConsent:{
 fontFamily:"Poppins",
 fontWeight:400,
 fontSize:"20px",
 lineHeight:"30px"
  },
  
  checkmark1: {
    width: "8px",
    height: "12px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    transform: "rotate(45deg)",
    position: "absolute" as "absolute", 
    top: "1px",
    left: "5px",
    opacity: 1,
  } as React.CSSProperties,
  linkStyle: {
    color: "#00796b",
    textDecoration: "underline",
  },
  checkboxCustom1: (isChecked: any): React.CSSProperties => ({
    borderRadius: "6px",
    marginRight: "10px",
    display: "inline-block",
    position: "relative",
    backgroundColor: isChecked ? "#1A4A42" : "transparent",
    width: "20px",
    height: "20px",
    border: "1px solid #78716C",
  }),
};

export default PatientDetails;
// Customizable Area End