import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End

import TermsConditionsEditController, {
  Props,
  configJSON,
} from "./TermsConditionsEditController";

export default class TermsConditionsEdit extends TermsConditionsEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
      <Dialog  open={this.props.states} maxWidth="sm" sx={webStyle.mainContainer}>
        <Box display="flex" justifyContent="space-between" >
        <DialogTitle  style={webStyle.termsCondition}>
          {this.props.pageType == "terms_and_condition" ? "Terms and Conditions" : "Privacy Policy"}
          </DialogTitle>
        <Box data-test-id="modalClose" onClick={this.props.modalOpen}>
        <CloseIcon   style={webStyle.closeIcon}  />
        </Box>
        </Box>
        <Divider />
        <DialogContent sx={webStyle.dialogContent} >
          <Typography  style={webStyle.mainTitle}>
            {this.state.pageMainTitle}
          </Typography>
          <Typography >
            -------------------------------------------------------
          </Typography>
          <Typography  paragraph style={webStyle.mainTitle}>
            {this.state.pageContent}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer:{
    "& .MuiPaper-root": { 
      borderRadius: "24px",
      minWidth:"808px",
      minHeight:"604px"
     }
   },
  termsCondition:{
    color:"#2C2524",
    fontSize:"24px",
    fontWeight:400,
    fontFamily:"Poppins",
    lineHeight:"36px"
  },
  mainTitle:{
    color:"#2C2524",
    fontSize:"16px",
    fontWeight:400,
    fontFamily:"Poppins",
    lineHeight:"24px"
  },
  dialogContent: {
    overflowY: 'auto',
    borderRadius: "10px",
    width: "722px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingLeft: "40px",
  
    "&::-webkit-scrollbar": {
      width: "6px", 
    },
    "&::-webkit-scrollbar-track": {
      background: "E7E5E4",
      borderRadius: "10px", 
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#A8A29E",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  
    scrollbarWidth: "thin", 
    scrollbarColor: "#A8A29E #E7E5E4",

  },
  closeIcon:{
    padding:"15px 25px",
    cursor:"pointer !important"
  },
  
};
// Customizable Area End
