export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const veryPoor = require("../assets/very_poor.svg").default;
export const poor = require("../assets/poor.svg").default;
export const well = require("../assets/Well.svg").default;
export const neutral = require("../assets/Neutral.svg").default;
export const veryWell = require("../assets/veryWell.svg").default;
export const skip = require("../assets/skip.svg").default;
export const emergency = require("../assets/emergency.png").default;
export const call = require("../assets/call.svg").default;
export const HeaderLogoImg = require("../assets/header.png").default;
export const googleLogo = require("../assets/googleLogo.png").default;
export const call1 = require("../assets/call.png").default;
export const cross = require("../assets/cross.png").default;
export const checkedIcon = require("../assets/checkbox_checked.svg").default;
export const uncheckIcon = require("../assets/checkbox_uncheck.svg").default;
