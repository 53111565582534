import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface ImageUrls {
  image: string;
}
interface PrimaryLinksAttributes {  
  primary_button_text: string;
  primary_link_url: string;
  external_link: boolean
}
interface SecondaryLinkAttributes {
  secondary_button_text: string;
  secondary_link_url: string;
  external_link: boolean
}
interface TagCloud {
  id: number;
  option_text: string;
}

interface SupportDesign {
  body: string;
  id: number;
  image: {
    image_url: string;
  };
  title: string;
  button_text: string;
}

interface PrimaryLinkAttributes {
  primary_button_text: string;
  primary_link_url: string;
  secondary_button_text: string;
  secondary_link_url: string;
  external_link: boolean;
}

interface MatchMakingSections {
  single_column_buttons: TagCloud[];
  single_column_checkbox: TagCloud[];
  three_column_checkbox: TagCloud[];
  tag_clouds: TagCloud[];
  support_design: SupportDesign[];
}

interface Attributes {
  page_title: string;
  sub_title: string;
  activated: boolean;
  main_title: string;
  main_body: string;
  help_footer: boolean;
  image_urls: ImageUrls;
  match_making_sections: MatchMakingSections;
  primary_links_section3_attributes: PrimaryLinkAttributes[];
}

interface MatchMakingResData {
  id: string;
  type: string;
  attributes: Attributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedAnswerBlockValue: string[];
  activeStep: string;
  skipped: Set<number>;
  listData: {progress_bar_text: string, progress_bar_stage: string}[];
  message: string;
  selectedSingleColumnCheck : number[],
  showOtherInput : boolean,
  otherValue: string,
  selectedThreeColumnCheck : number[];
  showOtherThreeColumnInput: boolean;
  selectedThreeColumnTile: number[],
  selectedTagCloudOptions : number[];
  matchMakingRes: MatchMakingResData;
  loading: boolean;
  showOtherSingleColumnInput : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfcustomonboardingflowcreator1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  matchMakingPageData:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedAnswerBlockValue: [],
      skipped: new Set(),
      listData: [
        { progress_bar_text: 'Stage A', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Stage B', progress_bar_stage: '2' },
        { progress_bar_text: 'Stage C',  progress_bar_stage: '3'}
      ],
      activeStep: 'Stage A',
      message: '',
      selectedSingleColumnCheck : [],
      showOtherInput : false,
      otherValue: '',
      selectedThreeColumnTile : [],
      selectedThreeColumnCheck : [],
      showOtherThreeColumnInput: false,
      selectedTagCloudOptions : [],
      matchMakingRes: {
          id: '',
          type: '',
          attributes: {
            activated: false,
            page_title:'',
            main_title: '',
            sub_title: '',
            main_body: '',
            help_footer: false,
            image_urls: {
              image: '',
            },
            match_making_sections: {
              single_column_buttons: [],
              single_column_checkbox: [],
              tag_clouds: [],
              support_design: [],
              three_column_checkbox: []
            },
            primary_links_section3_attributes: [],
          },
      },
      loading: false,
      showOtherSingleColumnInput : false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleMatchMakingPageResponse(from, message);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleMatchMakingPageResponse = async (from: string, message: Message) => {
    if (
      this.matchMakingPageData ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        if (!responseJson.errors && !responseJson.error_message) {
          this.setState({matchMakingRes:responseJson.data, message: '', loading: false});
        }
        if (!responseJson.errors && responseJson.error_message) {
          this.setState({ message: "Page not found", loading: false});
        }
        if (responseJson.errors) {
          this.setState({ message: "Page not found", loading: false});
        }
      }
    }
  }
  handleClick = (value: string) => {
    const { selectedAnswerBlockValue } = this.state;
    if (selectedAnswerBlockValue.includes(value)) {
      this.setState({
        selectedAnswerBlockValue: selectedAnswerBlockValue.filter((item) => item !== value),
      });
    } else {
      this.setState({
        selectedAnswerBlockValue: [...selectedAnswerBlockValue, value],
      });
    }
  };
  async componentDidMount(){
    this.matchMakingPage();
  }
  componentDidUpdate() {
      document.title = `${this.state.matchMakingRes?.attributes?.page_title} || TotalLifePlatform`;
  }
  matchMakingPage = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    this.setState({ loading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.matchMakingPageData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.matchMakingApiEndp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    const unique_slug = this.props.navigation.getParam("navigationBarTitleText") || '';
    const body = { unique_slug, page_type: "Match Making Template" };
     requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleSingleColumnCheckbox = (id: number, label: string) => {      
    this.setState((prevState) => {
      const isSelected = prevState.selectedSingleColumnCheck.includes(id);
      const newSelectedData = isSelected
        ? prevState.selectedSingleColumnCheck.filter((item) => item !== id)
        : [...prevState.selectedSingleColumnCheck, id];
        const otherLabels = ["Other", "other", "Others", "others", "Other answer"];
  
      return {
        selectedSingleColumnCheck: newSelectedData,
        showOtherSingleColumnInput: otherLabels.includes(label) ? !isSelected : prevState.showOtherSingleColumnInput,
      };
    });
  }

  handleNavigation = (pathname: string) => {
    const paramsData = pathname.split('/');
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), paramsData[0]);
    message.addData(getName(MessageEnum.NavigationPropsMessage), {...this.props});
    paramsData[1] && message.addData(getName(MessageEnum.NavigationScreenNameMessage), paramsData[1]);
    this.send(message);
    window.location.reload()
  }
  
  handleOtherInputChange = (event: { target: { value: string; }; }) => {
    this.setState({ otherValue: event.target.value });      
};

handleThreeColumnCheckbox = (id: number, label: string) => {      
  this.setState((prevState) => {
    const isSelected = prevState.selectedThreeColumnCheck.includes(id);
    const newSelectedData = isSelected
      ? prevState.selectedThreeColumnCheck.filter((item) => item !== id)
      : [...prevState.selectedThreeColumnCheck, id]

    const otherLabels = ["Other", "other", "Others", "others", "Other answer"];
    return {
      selectedThreeColumnCheck: newSelectedData,
      showOtherThreeColumnInput: otherLabels.includes(label) ? !isSelected : prevState.showOtherThreeColumnInput,
    };
  });
}

handleThreeColumnTiles=(id :number)=>{
  this.setState((prevState) => ({
    selectedThreeColumnTile: prevState.selectedThreeColumnTile.includes(id)
   ? prevState.selectedThreeColumnTile.filter((item) => item !== id)
   : [...prevState.selectedThreeColumnTile, id]
}));
}

handleTagCloudOption=(id:number)=>{
  this.setState((prevState)=>({
    selectedTagCloudOptions : prevState.selectedTagCloudOptions.includes(id) ? prevState.selectedTagCloudOptions.filter((item) => item !== id) : [...prevState.selectedTagCloudOptions,id]
  })
)}
  // Customizable Area End
}
