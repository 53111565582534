import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Object;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  startDate: Date,
  selectedTab: number,
  activeStep: number,
  selectedDate: any[],
  currentDate: null,
  playVideo: boolean,
  expanded: string,
  fullWidthVideo: boolean,
  isPlaying: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SelectedProviderController extends BlockComponent<
  Props,
  S,
  SS
> {
  webcamHTMLRef = React.createRef<HTMLVideoElement>();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      fullWidthVideo: false,
      currentDate: null,
      selectedTab: 0,
      expanded: "",
      activeStep: 2,
      startDate: new Date(),
      selectedDate: [],
      playVideo: false,
      isPlaying: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleTabChange = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  handleDateChange = (newDate: any) => {
    const isSelected = this.state.selectedDate.some(date => date.isSame(newDate, 'day'));
    if (isSelected) {
      this.setState({ selectedDate: (this.state.selectedDate.filter(date => !date.isSame(newDate, 'day'))) })
    } else {
      this.setState({ selectedDate: ([...this.state.selectedDate, newDate]) });
    }
  };

  isDateSelected = (date: any) => {
    return this.state.selectedDate.some(selectedDate => selectedDate.isSame(date, "day"));
  };

  handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    this.setState({ expanded: (isExpanded ? panel : false) })
  };

  playVideo = () => {
    this.setState({ playVideo: !(this.state.playVideo) })
  }
 
  fullWidthVideo = () => { this.setState({ fullWidthVideo: true }) };

  closeFullWidthVideo = () => { this.setState({ fullWidthVideo: false }) };

  goBack = () => { this.props.navigation.goBack() }

  // Customizable Area End
}
