import React from 'react';
import {
    Box, styled, Typography, Select, TextField, MenuItem
} from "@mui/material";
import InsuranceVerifyController, { Props } from "./InsuranceVerifyController";
import StepperData from '../../../components/src/StepperData.web';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { reprocessImage, tickImage, emptyImage } from './assets';

class InsuranceVerify extends InsuranceVerifyController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const planOptions = ['[Primary Plan]', 'Membership Plan', '[Other Insurance]'];
        return (
            <StyledWrapperInsuranceVerify>
                <section className='leftBorder'>
                    <Box className="mainContainer">

                        <Box className="headerStepper">
                            <StepperData
                                key={`stepper${this.state.activeStep}`}
                                listData={this.state.listData}
                                activeStep={this.state.activeStep}
                            />
                        </Box>
                        <Box className="pending-container">
                            <Box className="heading">
                                <Typography className="pending-title">Please verify the details below</Typography>
                                <Typography className="pending-information">Please review the Supplemental Insurance details</Typography>
                                <Box className="three-column-tile-container">
                                    <Box className="three-column-tile-box-Container">
                                        <Box
                                            className="boxes"
                                        >
                                            <Typography className="tileTitle">
                                                <span>Primary Insurance</span>
                                                <img src={tickImage} className='titleImage'
                                                alt="Image" 
                                                width={24} 
                                                height={24} />
                                            </Typography>
                                            <Box>
                                            <Box className="content-box">
                                            <Typography className="tilesubheadings">Plan type</Typography>
                                            <Typography className="tilesubTitles">[Selected Plan]</Typography>
                                                </Box>
                                                <Box className="content-box">
                                                <Box style={{
                                                        width: '273px',
                                                }}>
                                            <Typography className="tilesubheadings">First name on Card</Typography>
                                            <Typography className="tilesubTitles">Neelam</Typography>
                                                </Box>
                                                <Box style={{
                                                        width: '273px',
                                                }}>
                                            <Typography className="tilesubheadings">Last name on Card</Typography>
                                            <Typography className="tilesubTitles">Brar</Typography>
                                                </Box>
                                                </Box>
                                                <Box className="content-box">
                                            <Typography className="tilesubheadings">Member ID</Typography>
                                            <Typography className="tilesubTitles">1 E G 4 - T E 5 - M K 7 2</Typography>
                                                </Box>
                                                <Box className="content-box">
                                            <Typography className="tilesubheadings">Date of Birth</Typography>
                                            <Typography className="tilesubTitles">12 - 25 - 1993</Typography>
                                                </Box>
                                                <Box className="content-box">
                                            <Typography className="tilesubheadings">Zip Code</Typography>
                                            <Typography className="tilesubTitles">33127</Typography>
                                                </Box>
                                            </Box>
                                            <button
                                                data-test-id={'continuebtn'}
                                                className={`tileBtn`} >
                                                    <span>
                                                    Verified
                                                    </span>
                                                  <img src={tickImage} alt="Image" style={{
                                                    marginLeft: '8px',
                                                    marginTop: '-3px'
                                                 }}/>
                                            </button>
                                        </Box>

                                        <Box className="boxes" style={{
                                            border: '1px solid #E46760'
                                        }}
                                        >
                                            <Typography className="tileTitle">
                                                <span>Supplemental Insurance</span>
                                            <img src={emptyImage} alt="Image" width={24} height={24} className='titleImage'/>
                                            </Typography>
                                            <Box>
                                            <Box className="content-box">
                                            <Typography className="tilesubheadings">
                                                Plan type
                                            </Typography>
                                            <Select
                                            data-test-id="changePlanTestId"
                                            className="dropdownField"
                                            value={this.state.selectedPlan}
                                            onChange={this.handleDropdownChange}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                marginLeft: '14px',
                                                padding: '4px 12px',
                                                fontWeight: 400,
                                                borderRadius: '70px',
                                                width: '278px',
                                                backgroundColor: 'none',
                                                border: '1px solid #E6C79C',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                color: '#78716C',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                                },
                                                '.MuiOutlinedInput-input': {
                                                padding: '0px',
                                                },
                                            }}
                                            >
                                            <MenuItem value='Select a Plan'>
                                            [Secondary Plan]
                                            </MenuItem>
                                            {planOptions.map((plan) => (
                                                <MenuItem key={plan} value={plan}>
                                                {plan}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                                </Box>
                                                <Box className="content-box">
                                                <Box style={{
                                                        width: '273px',
                                                }}>
                                            <Typography className="tilesubheadings">
                                                First name on Card
                                            </Typography>
                                            <TextField
                                                variant="outlined"
                                                value={this.state.firstName}
                                                placeholder='Neelam'
                                                onChange={this.handleFirstNameChange}
                                                className="nameInputField"
                                                sx={{
                                                    borderRadius: '70px',
                                                    background: '#FFFFFF',
                                                    width: '181px',
                                                    border: '1px solid #E6C79C',
                                                    padding: '3px 12px',
                                                    marginTop: '4px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    'input::placeholder': {
                                                        color: '#2C2524',
                                                        fontWeight: 400,
                                                        fontFamily: 'Poppins'
                                                    },
                                                    input: {
                                                        fontFamily: 'Poppins', 
                                                        fontSize: '16px', 
                                                        color: '#78716C',
                                                        padding: '0',
                                                        fontWeight: 400
                                                      },
                                                }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            />
                                                </Box>
                                                <Box style={{
                                                        width: '273px',
                                                }}>
                                            <Typography className="tilesubheadings">Last name on Card</Typography>
                                            <TextField
                                                variant="outlined"
                                                value={this.state.lastName}
                                                placeholder='Brar'
                                                onChange={this.handleLastNameChange}
                                                className="nameInputField"
                                                sx={{
                                                    borderRadius: '70px',
                                                    background: '#FFFFFF',
                                                    width: '181px',
                                                    border: '1px solid #E6C79C',
                                                    padding: '3px 12px',
                                                    marginTop: '4px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    'input::placeholder': {
                                                        color: '#2C2524',
                                                        fontWeight: 400,
                                                        fontFamily: 'Poppins'
                                                    },
                                                    input: {
                                                        fontFamily: 'Poppins', 
                                                        fontSize: '16px', 
                                                        color: '#78716C',
                                                        padding: '0',
                                                        fontWeight: 400
                                                      },
                                                }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            />
                                                </Box>
                                                </Box>
                                                <Box className="content-box">
                                            <Typography className="tilesubheadings">Member ID</Typography>
                                            <TextField
                                                variant="outlined"
                                                value={this.state.memberId}
                                                onChange={this.handleMemberIdChange}
                                                className="nameInputField"
                                                placeholder='1 E G 4 - T E 5 - M K 7 2'
                                                sx={{
                                                    borderRadius: '70px',
                                                    background: '#FFFFFF',
                                                    width: '278px',
                                                    border: '1px solid #E6C79C',
                                                    marginLeft: '4px',
                                                    padding: '3px 12px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    'input::placeholder': {
                                                        color: '#2C2524',
                                                        fontWeight: 400,
                                                        fontFamily: 'Poppins'
                                                    },
                                                    input: {
                                                        fontFamily: 'Poppins', 
                                                        fontSize: '16px', 
                                                        color: '#78716C',
                                                        padding: '0',
                                                        fontWeight: 400
                                                      },
                                                }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            />
                                                </Box>
                                                <Box className="content-box">
                                            <Typography className="tilesubheadings">Zip Code</Typography>
                                            <TextField
                                                variant="outlined"
                                                value={this.state.zipCode}
                                                onChange={this.handlezipCodeChange}
                                                className="nameInputField"
                                                placeholder='33127'
                                                sx={{
                                                    borderRadius: '70px',
                                                    background: '#FFFFFF',
                                                    width: '278px',
                                                    border: '1px solid #E6C79C',
                                                    marginLeft: '22px',
                                                    padding: '3px 12px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    'input::placeholder': {
                                                        color: '#2C2524',
                                                        fontWeight: 400,
                                                        fontFamily: 'Poppins'
                                                    },
                                                    input: {
                                                        fontFamily: 'Poppins', 
                                                        fontSize: '16px', 
                                                        color: '#78716C',
                                                        padding: '0',
                                                        fontWeight: 400
                                                      },
                                                }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            />
                                                </Box>
                                            </Box>
                                            <button
                                                data-test-id={'continuebtn'}
                                                style={{
                                                    background: '#E46760',
                                                    color: 'white',
                                                    marginTop: '8px'
                                                }}
                                                className={`tileBtn`} >
                                                 <span>Reprocess</span>
                                                 <img src={reprocessImage} alt="Image" width={20} height={20} style={{
                                                    marginLeft: '8px'
                                                 }}/>
                                              </button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="call-box-container">
                                <Typography className="pending-secondary-text">
                                    If this information looks correct, please <span style={{
                                    color:'#E46760',
                                    textDecoration:'underline',
                                    cursor: "pointer",
                                }}>Schedule a call </span>
                                so we can finish the verification process.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </section>
            </StyledWrapperInsuranceVerify>
        )
    }
}

const StyledWrapperInsuranceVerify = styled('div')({
    width: "100%",
    margin: "0 auto",
    "& *": {
        boxSizing: "border-box",
    },
    "& .image-container": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '173px'
    },
    "& .information": {
        maxWidth: "600px",
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
        marginBottom: '40px'
    },
    "& .heading": {
        margin: "auto",
        fontWeight: 400,
    },
    "& .button": {
        margin: "auto",
        marginBottom: '265px',
        "display": "flex",
        "justifyContent": "center",
        width: "392px",
        padding: '16.5px',
        background: "#E46760",
        border: "none",
        borderRadius: '50px',
        color: "white",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '1px'
    },
    "& .secondary-text": {
        display: "flex",
        justifyContent: "center",
        letterSpacing: "1px",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        color: "#807C7C",
        border: "none",
        background: "none",
        cursor: "pointer",
        margin: 'auto',
        textDecoration: 'underline',
        marginBottom: '40px',
        marginTop: '24px',
    },
    "& .secondary-button": {
        margin: "auto",
        marginBottom: '20px',
        "display": "flex",
        "justifyContent": "center",
        width: "392px",
        padding: '16.5px',
        background: "none",
        border: "1px solid #E46760",
        borderRadius: '50px',
        color: "#E46760",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '1px'
    },
    '& .type-label': {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        color: '#2C2524',
        marginBottom: '4px'
    },
    "& .imgParent": {
        display: "flex",
        minHeight: "120px",
        justifyContent: "center",
        height: "auto",
        width: "100%",
    },
    "& .image": {
        height: "20%",
        width: "20%",
    },
    "& .description": {
        maxWidth: "80%",
        textAlign: "center",
        margin: "auto",
        marginTop: "8%",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        marginBottom: "8%",
    },
    "& .boxTitle": {
        "color": "#2C2524",
        "fontSize": "29px",
        letterSpacing: '0.5px',
        "fontFamily": "Poppins",
        "textAlign": "center",
        fontWeight: 500,
        width: "100%",
        marginBottom: '32px'
    },
    '& .mainBox': {
        padding: '32px 20px'
    },
    "& .box1": {
        margin: 'auto',
        width: '640px',
        border: "1px solid #D6D3D1",
        borderRadius: "24px",
        background: '#FFFFFF'
    },
    "& .title": {
        width: "100%",
        height: "auto",
        maxWidth: "60%",
        color: "#2C2524",
        margin: "40px auto",
        fontWeight: 600,
        fontSize: "41px",
        fontFamily: "Raleway",
        textAlign: "center",
        LineHeight: '60px',
        LetterSpacing: '0.5px'
    },
    "& .headerStepper": {
        justifyContent: "center",
        display: "flex",
    },
    "& .leftBorder": {
        borderLeft: "32px solid #1A4A42",
    },
    "& .mainContainer":{
        backgroundColor: '#FFFAF6',
    },
    "& .pending-title": {
        width: "100%",
        height: "auto",
        color: "#2C2524",
        margin: "60px auto 32px",
        fontWeight: 600,
        fontSize: "41px",
        fontFamily: "Raleway",
        textAlign: "center",
        LineHeight: '53.3px',
        LetterSpacing: '0.5px'
    },
    "& .content-box":{
        display: 'flex',
        gap: '10px',
        marginBottom:'16px',
        "@media screen and (max-width:555px)": {
            display: 'block',
          },
    },
    "& .tilesubheadings": {
        width: "auto",
        height: "auto",
        color: '#2C2524',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        alignContent: 'center'
    },
    "& .tilesubTitles": {
        width: "auto",
        color: '#807C7C',
        height: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
    },
    "& .pending-information": {
        maxWidth: "531px",
        width:'531px',
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
        marginBottom: '32px',
        "@media screen and (max-width:555px)": {
            maxWidth: "auto",
            width:'auto',
          },
    },
    "& .tileTitle": {
        width: "auto",
        fontSize: "24px",
        textAlign: "center",
        marginBottom : "14px",
        color: '#2C2524',
        height: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'center'
    },
    "& .titleImage": {
             marginLeft: '8px',
             marginTop: '5px'
    },
    "& .tileBtn": {
        cursor: 'pointer',
        padding: "9px 10px 9px 14px",
        display: 'flex',
        justifyContent: 'center',
        height: "auto",
        fontWeight: 400,
        color: "#78716C",
        fontSize: '20px',
        border: "1px solid #D6D3D1",
        width: "380px",
        borderRadius: "26px",
        background: "#F5F5F4",
        flexShrink: 0,
        marginBottom: '32px',
        fontfamily: 'Poppins',
        margin: '0px auto',
        "@media screen and (max-width: 900px)": {
            width: "350px",
        },
        "@media screen and (max-width: 500px)": {
            width: "270px",
        }  
    },
    "& .boxes": {
        minWidth: "250px",
        padding: "20px 22px 20px 20px",
        margin: "0px 10px 10px 10px",
        overflow: "hidden",
        border: "1px solid #D6D3D1",
        borderRadius: "16px",
        display: "flex",
        flexBasis: "calc(33.33% - 20px)",
        flexGrow: 1,
        flexDirection: "column", 
        background:'#FFFFFF',
        "@media screen and (max-width: 900px)": {
            width: "407px",
            minWidth: "407px",
        }  
    },
    "& .three-column-tile-box-Container": {
        margin: "auto",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "912px",
        display: "flex",
        flexWrap: "wrap",
    },
    '& .pending-secondary-text': {
        fontWeight: 400,
        fontSize: '20px',
        fontFamily: 'Poppins',
        cursor: "pointer",
    },
    '& .call-box-container': {
        minWidth: "967px",
        width: "100%",
        maxWidth: "100%",
        display: 'flex',
        gap: '10px',
        margin: '60px auto 0px',
        paddingBottom: '232.5px',
        borderRadius: '16px',
        justifyContent: 'center',
        "@media screen and (max-width: 1080px)": {
            minWidth: "auto",
            width: "auto",
            padding: '10px',
            textAlign: 'center'
        }    
    }    
})

export default InsuranceVerify;