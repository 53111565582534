// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TextField,
    InputLabel
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { HeaderLogoImg} from '../../../blocks/landingpage/src/assets';
import { activeCall, activeChat, chatIcon, dropdownIcon, uploadMediaIcon, sendIcon, videoIcon } from './assets';

import NeedHelpController, {
    Props,
} from "./NeedHelpController";
import { ResolveCondition, handleNavigation } from '../../../blocks/utilities/src/CustomHelpers';

class NeedHelp extends NeedHelpController {
    constructor(props: Props) {
        super(props);
    }

    handleDisabledBtn = (data: string | boolean) => {
        return data ? false: true
    }

    render() {
        return (
            <StyledWrapper>
                <section style={{
                  borderLeft: "32px solid #1A4A42",
                }}>
                <Box className="header-section">
                    <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                </Box>
                <Box className="container-elm">
                    <Box className="content-container">
                        <Typography
                            className='headingText'
                            dangerouslySetInnerHTML={{__html: "Need Help? <span style='color: #1A4A42'>Let us Help You</span>"}}
                        />
                        <Typography
                            className='bodyText'
                            dangerouslySetInnerHTML={{__html: "Whether you have a quick question or need more personalized assistance, we're here to help you every step of the way."}}
                        />
                        <Typography
                            className='subText'
                            dangerouslySetInnerHTML={{__html: "Choose from our convenient options:"}}
                        />
                    </Box>
                    <Box className="card-container">
                    <Box className="card">
                            <Typography
                                variant="h1" 
                                component="h2"
                                className='cardHeaderText'
                                dangerouslySetInnerHTML={{__html: "Online Chat"}}
                            />
                            <Typography
                                className='cardBodyText'
                                style={{
                                    width: '348px',
                                    margin: '40px auto 56px'
                                }}
                                dangerouslySetInnerHTML={{__html: "Get support with our Ai assistant"}}
                            />
                            <CustomButton data-test-id="button-elm" onClick={() => this.handleChat()} disabled={this.handleDisabledBtn(this.state.button_text)} className="submit-button"><span>{this.state.button_text}</span><img src={this.state.button_text ? activeChat: chatIcon} alt="chat button" /></CustomButton>
                        </Box>   <Box className="card">
                            <Typography
                                variant="h1" 
                                component="h2"
                                className='cardHeaderText'
                                dangerouslySetInnerHTML={{__html: "Virtual Assitant"}}
                            />
                            <Typography
                                className='cardBodyText'
                                style={{width: '348px',
                                    margin: '40px auto 56px'
                                }}
                                dangerouslySetInnerHTML={{__html: "Get instant in a Videocall"}}
                            />
                             <CustomButton data-test-id="button-elm" onClick={() => handleNavigation('ScheduledSession', this.props)} disabled={this.handleDisabledBtn('join virtual waiting room')} className="submit-button"><span>{'join virtual waiting room'}</span><img src={videoIcon} alt="chat button" /></CustomButton>
                        </Box>
                        <Box className="card">
                            <Typography
                                variant="h1" 
                                component="h2"
                                className='cardHeaderText'
                                dangerouslySetInnerHTML={{__html: "Call us Now"}}
                            />
                            <Typography
                                className='cardBodyText'
                                style={{width: '348px',
                                    margin: 'auto'
                                }}
                                dangerouslySetInnerHTML={{__html: "Get instant support from a real person"}}
                            />
                            <CustomButton data-test-id="button-elm" onClick={() => {}} disabled={this.handleDisabledBtn(this.state.button_text)} className="call-button"><span>1-800-567-LIFE</span><img src={activeCall} alt="call button" /></CustomButton>
                        </Box>
                        <Box className="card">
                            <Box style={{display: "flex", gap: "4px", flexDirection: "column"}}>
                                <Typography
                                    className='cardHeaderText'
                                    dangerouslySetInnerHTML={{__html: "Request a call back"}}
                                />
                                <Typography
                                    className='cardBodyText'
                                    dangerouslySetInnerHTML={{__html: "Choose a time that best suits you"}}
                                />
                                <Typography
                                    className='cardSubHeaderText'
                                    dangerouslySetInnerHTML={{__html: "*Calls will be returned today or on the next business day"}}
                                />
                            </Box>
                            <Box style={{marginTop: '12px'}} className={`menu-container ${this.state.toggleMenu ? 'active': ''}`} ref={this.dropDownRef}>
                                <CustomBox className="select-btn" data-test-id="menu-section" onClick={() => this.toggleMenuData()}>
                                    <span className={`${ResolveCondition(this.state.selectedMenu,'active-text','')}`}>{this.state.selectedMenu || "Choose a time frame"}</span>
                                    <img className={`${ResolveCondition(this.state.toggleMenu,'active','')}`} src={dropdownIcon} alt="dropdown" />
                                </CustomBox>
                                {this.state.toggleMenu && <>
                                {this.state.menuList.map(item =>
                                    <Box key={`menuData${item}`} data-test-id="menu-data" className={`menu-text default-text ${ResolveCondition(this.state.selectedMenu === item,"selected","")}`} onClick={() => this.handleSelectData(item)}><span className='active-text'>{item}</span></Box>
                                )}</>}
                            </Box>
                            <CustomButton data-test-id="button-elm" onClick={() => handleNavigation("scheduledCallback", this.props)} disabled={this.handleDisabledBtn(this.state.selectedMenu)} className="confirm-button">Confirm Request</CustomButton>
                        </Box>
                    </Box>
                    <CustomButton data-test-id="button-elm" onClick={() => this.props.navigation.goBack()} disabled={this.handleDisabledBtn(this.state.button_text)} className="back-button">Back</CustomButton>
                </Box>
                </section>
                {this.state.showModal && <CustomDialog
                    maxWidth="lg"
                    open={this.state.showModal}
                    onClose={() => this.handleCloseModal()}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    className='chat-modal'
                    data-test-id="close-modal"
                >
                    <DialogTitle id="customized-dialog-title">
                        Hello! Need Help?
                    </DialogTitle>
                    <IconButton
                        onClick={() => this.handleCloseModal()}
                        aria-label="close"
                        data-test-id="close-modal"
                        sx={{
                            width: '24px',
                            color: '#44403C',
                            right: 8,
                            top: 8,
                            marginRight: '44px',
                            marginTop: '33px',
                            height: '24px',
                            position: 'absolute',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent sx={{ m: 0, p: 0 }}>
                        <Typography
                            dangerouslySetInnerHTML={{__html: "Lorem ipsum dolor sit amet consectetur. Ac diam a cum sed nisl fringilla quis molestie urna. Id sit aliquet."}}
                            className='bodyText'
                        />
                        <Box className="chat-container">
                            <Typography
                                className='headingTextData'
                                dangerouslySetInnerHTML={{__html: "Session Chat"}}
                            />
                            <Box style={{ padding: "0 12px", 
                                          flexDirection: "column" ,
                                          gap: "10px", 
                                          display: "flex", 
                                        }}>
                                <Box className="reply-section">
                                    <Typography>
                                        <span className='nameField'>Total Life:</span>
                                        <span className='time-field'>14:37</span>
                                    </Typography>
                                    <Typography className='bodyElm'>
                                    Hi there! I'm here to help.What can I assist you with today?
                                    </Typography>
                                </Box>
                                <Box className="reply-section client">
                                    <Typography>
                                        <span className='nameField client'>You:</span>
                                        <span className='time-field'>14:38</span>
                                    </Typography>
                                    <Typography className='bodyElm client'>
                                        I need some help with...
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="footer-elm">
                                 <CustomTextField 
                                    placeholder='Type here'
                                    data-test-id="message-field"
                                    value={this.state.messageText}
                                    onChange={(event) => this.handleMessage(event.target.value)}
                                    multiline />
                                <Box className="right-section">
                                <InputLabel>
                                    <img src={uploadMediaIcon} alt="upload media" />
                                    <input ref={this.uploadRef}
                                        style={{ display: "none" }}
                                        onChange={(event) => this.handleFileChange(event)}
                                        type="file"
                                        data-test-id="file-field"/>
                                </InputLabel>
                                    <CustomButton 
                                    data-test-id="button-elm" 
                                    disabled={this.handleDisabledBtn(this.state.messageText)} 
                                    className="send-button">
                                        <img src={sendIcon} alt="send btn" />
                                    </CustomButton>
                                </Box></Box>
                        </Box></DialogContent>
                </CustomDialog>}
            </StyledWrapper>
        );
    }
}

const CustomTextField=styled(TextField) (({
    scrollbarWidth: "none",
    overflow: "auto",
    MsoverflowStyle: "none",
    width: "88%",
    '&::-webkit-scrollbar' : {
        display: "none",
    },
    '& .MuiInputBase-root' : {
      backgroundColor: 'transparent',
      height: "44px",
      '&:hover' : {
        backgroundColor: 'transparent'
      } ,
    } ,
    '& .MuiInputBase-input' : {
        color: "#2C2524",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        fontSize: "16px",
        padding: '12px',
        fontFamily: "Inter" ,
        '&::placeholder' : {
            opacity: 1,
            color: "#A8A29E",
        },
    },
    '& .MuiOutlinedInput-root' :{
      '& fieldset' :{
          alignItems: "center",
          border: "1px solid transparent",
          display: "flex",
      } ,
      '&:hover fieldset' : {
        border: "1px solid transparent",
      },
      '&.Mui-focused fieldset' : {
        border:"1px solid transparent"
      },
    },
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: "none",
      borderRadius: "24px",
      padding: '32px 40px 40px 40px',
      width: '710px'
    },
    "& .bodyText, & .MuiDialogTitle-root": {
        fontFamily: "Poppins",
        textAlign: "center",
        backgroundImage: `linear-gradient(0deg, #2C2524, #2C2524),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textFillColor: "transparent"
    },
    '& .MuiDialogTitle-root': {
        fontSize: "29px",
        fontWeight: 500,
        lineHeight: "40.6px",
        letterSpacing: "0.5px",
        padding: '0px',
        paddingBottom: '32px'
    },
    "& .bodyText": {
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px"
    },
    "& .chat-container": {
        marginTop: "32px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        borderRadius: "16px",
        border: "1px solid #D6D3D1",
        "& .headingTextData": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            padding: "14px 12px",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#000"
        }
    },
    "& .footer-elm": {
        height: "68px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: '102px',
        borderTop: "1px solid #D6D3D1"
    },
    "& .reply-section": {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "6px",
        borderRadius: "8px",
        backgroundColor: "#E8EDEC",
        width: "40%",
        maxWidth: "265px",
        "&.client": {
            border: "1px solid #E46760",
            backgroundColor: "transparent",
            alignSelf: "end",
            maxWidth: "265px"
        },
        "& .time-field": {
            color: "#807C7C"
        }, 
        "& .bodyElm": {
            fontSize: "16px",
            color: "#44403C",
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Inter",
            "&.client": {
                color: "#57534E",
            }
        },
        "& .nameField": {
            marginRight: "8px",
            fontStyle: "italic",
            color: "#1A4A42",
            "&.client": {
                backgroundImage: `linear-gradient(0deg, #2C2524, #2C2524),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    fontWeight: 700,
                    textFillColor: "transparent",
                    fontStyle: "normal",
                    color: "transparent",
            }
        },
        "& .nameField, & .time-field": {
            lineHeight: "19.5px",
            fontSize: "13px",
            fontFamily: "Poppins",
            fontWeight: 400,
        },  
    },
    "& .right-section": {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        "& .send-button": {
            width: "40px",
            height: "40px",
            borderRadius: "6.15px",
            backgroundColor: "#1A4A42",
            minWidth: "20px",
            marginRight: '12px'
        },
        '& .Mui-disabled.send-button': {
            opacity: 0.4
        }
    },
    "& .MuiDialogContent-root": {
        overflow: "auto",
        MsoverflowStyle: "none",
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {
            display: "none"
        }
    }
}));

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    '& .container-elm': {
        height: "auto",
        minHeight: "78px",
        display: "flex",
        flexDirection: "column",
        background: '#FFFAF6',
    },
    '& .content-container': {
        display: "flex",
        flexDirection: "column",
        width: "48%",
        margin: "0 auto",
        "& .headingText, & .subText, & .bodyText": {
            textAlign: "center",
            margin: 0
        },
        "& .headingText": {
            fontFamily: "Raleway",
            fontSize: "50px",
            fontWeight: 700,
            lineHeight: "120%",
            letterSpacing: "0.5px",
            marginTop: "75px"
        },
        "& .subText": {
            color: "#2C2524",
            fontWeight: 700,
        },
        '& .bodyText': {
            color: "#44403C",
            fontWeight: 400,
            margin: "12px 0",
        },
        "& .subText, & .bodyText": {
            fontFamily: "Poppins",
            fontSize: "20px",
            lineHeight: "30px"
        }
    },
    "& .header-section": {
        height: "78px",
        display: "flex",
        background: 'white',
        alignItems: "center",
        borderBottom: "1px solid #E7E5E4",
        width: "100%",
        '& img': { paddingLeft: "3%" },
    },
    "& .logoImg": {
        marginLeft: "144px",
        width: "162px",
        height: "41.24px"
      },
    '& .card-container': {
        display: "flex",
        gap: "24px",
        margin: "32px auto 56px",
        width: "100%",
        justifyContent: 'center',
        flexWrap: 'wrap',
        maxWidth: '1150px',
        "@media screen and (max-width:1250px)": {
            maxWidth: "1000px",
        },
        "@media screen and (max-width:1150px)": {
            maxWidth: "923px",
        },
        "@media screen and (max-width:950px)": {
            display: 'grid'
        },
        '& .card': {
            padding: "20px 42px",
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "16px",
            border: "1px solid #D6D3D1",
            width: "480px",
            background: '#FFFFFF',
            flexGrow: 1,
            flexBasis: "calc(33.33% - 20px)",
            minWidth: "240px",
            // height: '287px',
            "@media screen and (max-width:980px)": {
                height: 'auto',
            },
            '& .cardHeaderText, & .cardSubHeaderText, & .cardBodyText': {
                fontFamily: "Poppins",
                textAlign: "center"
            },
            '& .cardSubHeaderText p': {
              margin:0,
              padding: 0
            },
            '& .cardHeaderText': {
                fontSize: "35px",
                fontWeight: 500,
                lineHeight: "49px",
                letterSpacing: "0.5px",
                color: "#2C2524"
            },
            '& .cardSubHeaderText': {
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "19.5px",
                color: "#44403C"
            },
            '& .cardBodyText': {
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#44403C"
            }
        }
    },
    '& .menu-text': {
        height: "46px",
        padding: "0 12px 0 16px",
        alignItems: "center",
        display: "flex",
        cursor: "pointer",
        color: "#44403C",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        borderBottom: "1px solid #D6D3D1",
        ':last-child': {
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
            borderBottom: "none",
        },
        "&.selected": {
            backgroundColor: "#F5F5F4"
        }
    },
    '& .menu-container': {
        borderRadius: "24px",
        border: "none",
        '&.active': {
            outline: "2px solid #E6C79C",
            borderTop: "none !important",
            "& .select-btn": {
                outlineWidth: "2px"
            }
        }
    },
});

const CustomButton=styled(Button)(({ theme })=>({
    borderRadius: '24px',
    fontFamily: "Raleway",
    justifyContent: "center",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    height: "56px",
    lineHeight: "23.48px",
    display: "flex",
    alignItems: "center",
    textTransform: 'none',
    gap: "8px",
    '&.submit-button, &.confirm-button' :{
        width: "100%",
        color: '#FFFAF6',
        fontSize: '20px',
        // margin: "40px auto 0",
        fontFamily: 'Raleway',
        backgroundColor: '#E46760',
        fontWeight: 700,
    },
    '&.back-button' :{
        color: '#78716C',
        marginBottom: "124.5px",
        height: "max-content",
        marginLeft: "375.5px",
        backgroundColor: 'transparent', 
        width: "max-content",
        "@media screen and (max-width:1900px)" :{
            marginLeft: "350.5px"
        },"@media screen and (max-width:1850px)": {
            marginLeft: "329.5px",
        },
        "@media screen and (max-width:1800px)" : {
            marginLeft: "300.5px"
        },"@media screen and (max-width:1750px)" : {
            marginLeft: "272.5px"
        },"@media screen and (max-width:1700px)" :{
            marginLeft: "248.5px"
        },"@media screen and (max-width:1650px)" :{
            marginLeft: "214.5px"
        },"@media screen and (max-width:1600px)": {
            marginLeft: "192.5px"
        },"@media screen and (max-width:1500px)": {
            marginLeft: "177.5px"
        },"@media screen and (max-width:1450px)":{
            marginLeft: "122.5px"
        },"@media screen and (max-width:1400px)":{
            marginLeft: "110.5px"
        },"@media screen and (max-width:1350px)":{
            marginLeft: "69.5px"
        },"@media screen and (max-width:1250px)":{
            marginLeft: "90.5px"
        },"@media screen and (max-width:950px)":{
            marginBottom: '124px',
            margin:"auto",
        },},
    '&.confirm-button' :{
        marginTop: "14px",
        width: "100%"},
    '&.call-button':{
        width: "100%",
        fontSize: '29px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        color: '#2C2524',
        backgroundColor: '#F5F5F4',
    },
    '&.Mui-disabled.submit-button, &.Mui-disabled.confirm-button':{
        pointerEvents: 'none',
        color: "#A8A29E",
        backgroundColor: '#E7E5E4',
        boxShadow: 'none',
        cursor: 'not-allowed',
    },
    '&.submit-button:hover':{
        backgroundColor: '#E46760',
    },
}))

const CustomBox = styled(Box)(({ theme }) => ({
    '&.select-btn': {
        borderRadius: "24px",
        outline: "1px solid #E6C79C",
        height: "56px",
        backgroundColor: '#fff',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 12px 0 16px",
        fontFamily: "Poppins",
        color: "#A8A29E",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        '& img.active': { rotate: "180deg", },
        '& .active-text': {
            color: "#2C2524"
        }
    },
}))

export default NeedHelp;
// Customizable Area End