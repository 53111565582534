export const HeaderLogoImg = require("../../cfcustomonboardingflowcreator1/assets/header.png").default;
export const activeCall = require("../assets/activecall.svg").default;
export const activeChat = require("../assets/activeChat.svg").default;
export const chatIcon = require("../assets/disabledChat.svg").default;
export const uploadMediaIcon = require("../assets/uploadMediaIcon.svg").default;
export const scheduledCall = require("../assets/scheduledCall.svg").default;
export const sendIcon = require("../assets/sendIcon.svg").default;
export const dropdownIcon = require("../../email-account-registration/assets/dropdown.svg").default;
export const videoIcon = require("../assets/video.png").default;
export const supportAgentIcon = require("../assets/support_agent.png").default;
export const videoSession = require("../assets/videoSessoin.png").default;
export const chatBubble = require("../assets/chat_bubble.png").default;
export const microphone = require("../assets/mic_none.png").default;
export const moreBtn = require("../assets/more_vert.png").default;
export const fullScreen = require("../assets/fullscreen.png").default;