import React from 'react';
import {
    Box, styled, Button, Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Autocomplete,
    TextField,
} from "@mui/material";
import CareTeamInformationController, { Props } from "./CareTeamInformationController";
import StepperData from '../../../components/src/StepperData.web';
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { AssembleIcon, SearchIcon } from "./assets";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";


const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#1A4A42',
        borderRadius: "50%",
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#1A4A42',
        },
    },
});

class CareTeamInformation extends CareTeamInformationController {
    constructor(props: Props) {
        super(props);
    }
    StyledRadio = (props: any) => {

        const classes = useStyles();

        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                {...props}
            />
        );
    };

    ProviderTypeOption: string[] = [
        "Therapist",
        "Psychiatrist",
        "Nutritionist/Dietitian",
    ];
    render() {

        return (
            <>
                <StyledWrapperCheckInsurance>
                    <section className='leftBorder'></section>
                    <Box className="mainContainer">
                        <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
                            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                        </Box>
                        {this.state.AssembleCareTeam === false ? <Box>
                            <Box className="headerStepper">
                                <StepperData
                                    key={`stepper${this.state.activeStep}`}
                                    listData={this.state.listData}
                                    activeStep={this.state.activeStep}
                                />
                            </Box>
                            <Box className="heading">
                                <Typography className="title">Let's Design Your Care Team!</Typography>
                                <Typography className="information">Where do you live?</Typography>
                                <Typography className='subtext'>Knowing your location lets us match <br />you with clinicians licensed in your state.</Typography>
                            </Box>
                            <Box className="Box1">
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}><Typography className='stateSelect' >Select your current State</Typography>
                                    <Box style={{ position: 'relative', border: '1px solid yellow', borderRadius: 30, overflow: 'hidden', paddingLeft: 38, paddingBlock: 12 }}>
                                        <input data-test-id="handleState" onChange={this.handleStateInput} className='search-input-tag' type="text" style={{ width: '100%', border: 'none' }} />
                                        <img src={SearchIcon} style={{ width: 24, position: 'absolute', top: 10, left: 10 }} alt="" />
                                    </Box>
                                    <Typography className='subStateSelect'>if you are a snowbird select the state that you will be attending sessions from</Typography>
                                </Box>
                                <Box>
                                    <FormControl component="fieldset">
                                        <FormLabel className='stateSelect' style={{ marginBottom: "12px" }}>
                                            Do you prefer video sessions or phone calls?</FormLabel>
                                        <RadioGroup aria-label="gender" name="customized-radios">
                                            <FormControlLabel className='radioOptions' data-test-id="TestRadio1" onClick={() => this.handleRadioOption("Video")} value="Video" control={<this.StyledRadio />} label="Video sessions" />
                                            <FormControlLabel className='radioOptions' data-test-id="TestRadio2" onClick={() => this.handleRadioOption("Phone")} value="Phone" control={<this.StyledRadio />} label="Phone Calls" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px", marginBottom: "32px" }}><Typography className='stateSelect' >Provider Type</Typography>
                                    <Box>
                                        <Box className="hide-show-class" style={{ position: 'relative', border: '1px solid yellow', borderRadius: 15, overflow: 'hidden' }}>
                                            <input data-test-id="handleChangeText" onClick={() => { this.setState({ openDropDown: true }) }} readOnly value={this.state.dropDownValue} className='drodown-input-tag' placeholder='Select One' type="text" style={{ width: '100%', border: '1px solid yellow', padding: 15, borderRadius: 15 }} />
                                            {this.state.openDropDown ? < KeyboardArrowUp style={{ width: 24, position: 'absolute', top: 10, right: 10 }} /> : <KeyboardArrowDown style={{ width: 24, position: 'absolute', top: 10, right: 10 }} />}
                                            {this.state.openDropDown === true && <Box style={this.state.openDropDown === true ? { display: "block" } : { display: "none" }}>
                                                {this.ProviderTypeOption.map((element: string) => { return <Typography data-test-id="handleProvider" onClick={() => { this.handleProviderType(element) }} style={{ fontWeight: 400, padding: 10, fontSize: "13px", borderBottom: '1px solid lightgray', fontFamily: "Poppins" }}>{element}</Typography> })}
                                                <Typography data-test-id="handleProvider1" onClick={() => { this.handleProviderType("Medical Doctor") }} style={{ fontWeight: 400, padding: 10, fontSize: "13px", fontFamily: "Poppins" }}>Medical Doctor</Typography>
                                            </Box>}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='chooseProvider'>
                                <Box onClick={this.goBack} data-test-id="goBackTest" >
                                    <Typography style={{ color: '#A8A29E', fontSize: 20, fontWeight: 700, lineHeight: 1, position: 'absolute', top: "25px", fontFamily: "Raleway" }}>
                                        Back
                                    </Typography>
                                </Box>
                                <Box style={{ margin: 'auto', width: '392px', }}>
                                    <Typography data-test-id="AssembleCareTeamTest" onClick={this.AssembleCareTeam} className={this.state.validateToConfirmPage === true ? "SelectedInsurance" : 'unSelectedInsurance'} >See my recommendations</Typography>
                                </Box>
                                <Box></Box>
                            </Box>
                        </Box>
                            : <Box style={{ display: "flex", flexDirection: "column", marginTop: "140px", alignItems: "center", justifyContent: "center" }}>
                                <Typography style={{ fontWeight: 600, fontSize: "41px", fontFamily: "Raleway", color: "#2C2524", textAlign: "center" }}>We're assembling your Care team,<br />
                                    please stand by...</Typography>
                                <Box className='assembleBox'>
                                    <img className='assemble' src={AssembleIcon} />
                                    <Typography style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Poppins", color: "#2C2524" }}>Did you know?</Typography>
                                    <Typography style={{ fontFamily: "Poppins", fontWeight: "400px", fontSize: "24px" }}>Therapy gives you a safe space to explore<br /> your thoughts, feelings, and concerns.</Typography>
                                </Box>
                            </Box>}
                    </Box>
                </StyledWrapperCheckInsurance>
            </>
        )
    }
}


const StyledWrapperCheckInsurance = styled('div')({
    width: "100%",
    margin: "0 auto",
    "& *": {
        boxSizing: "border-box",
    },
    '& .textarea-div:focus-visible': { border: 'none', outline: 'none' },
    "& .radioOptions": { fontWeight: 400, fontSize: "16px", color: "#2C2524" },
    "& .MuiAutocomplete-clearIndicator": { display: "none" },
    "& .logoImg": {
        marginLeft: "144px",
        width: "162px",
        height: "41.24px"
    },
    '& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root': {
        color: '#2C2524 !important'
    },
    "& .assembleBox": { display: "flex", flexDirection: "column", gap: "45px", marginTop: "90px", textAlign: "center", alignItems: "center" },
    "& .assemble": { width: "170px", height: "190px", marginTop: "90px" },
    "& .MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input": { width: "496px", height: "44px", paddingRight: 65, color: "#A8A29E" },
    "& .MuiInputBase-root.MuiOutlinedInput-root": { width: "496px", height: "44px", border: "1px solid #E6C79C", borderRadius: 24, padding: '3.5px 4px 1px 10px', textAlign: "center" },
    "& .header": {
        display: "flex",
        alignItems: "center",
        height: "auto",
        minHeight: "78px",
        width: "100%",
        backgroundColor: "#fff"
    },
    '& .subtext': {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "24px",
        textAlign: "center",
        color: "#2C2524",
        lineHeight: "36px",
        marginBottom: "32px",
        marginTop: "24px",
    },
    "& .information": {
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
    },
    "& .stateSelect": {
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: "#2C2524"
    },
    "& .subStateSelect": {
        fontWeight: 400,
        fontSize: "13px",
        fontFamily: "Poppins",
        color: "#78716C"
    },

    "& .heading": {
        margin: "auto",
        fontWeight: 400,
    },
    "& .button": {
        margin: "auto",
        marginBottom: '32px',
        "display": "flex",
        "justifyContent": "center",
        width: "600px",
        padding: '16.5px',
        border: "none",
        borderRadius: '50px',
        color: "white",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '1px',
        cursor: 'pointer',
    },

    "& .secondary-button": {
        display: "flex",
        justifyContent: "center",
        lineHeight: "23.48px",
        fontFamily: "Raleway",
        textDecoration: 'underline',
        background: "none",
        letterSpacing: "1px",
        fontWeight: 700,
        fontSize: "20px",
        color: "#E46760",
        border: "none",
        cursor: "pointer",
        margin: 'auto',
    },
    '& .mainContentBox': {
        padding: '32px 20px'
    },
    "& .box1": {
        width: '496px',
        margin: 'auto',
        display: "flex",
        flexDirection: "column",
        gap: "32px"
    },
    '& .hide-show-class': {

    },
    '& .search-input-tag:hover, .search-input-tag:focus': {
        border: 'none !important',
        outline: 'none !important',
    },
    '& .drodown-input-tag': {
        borderTop: 'none !important',
        borderLeft: 'none !important',
        borderRight: 'none !important',
    },
    '& .drodown-input-tag:hover, .drodown-input-tag:focus': {
        borderBottom: '1px solid yellow !important',
        outline: 'none !important',
    },
    '& .custom-dropdown': {
        display: 'none !important',
    },
    '& .custom-dropdown p:hover': {
        background: '#00000010',
        cursor: 'pointer'
    },
    "& .boxes": {
        display: "flex",
        gap: "20px",
        height: "auto",
        width: "100%",
        marginBottom: "23px",
        flexDirection: "row",
    },
    "& .title": {
        width: "100%",
        fontWeight: 700,
        height: "auto",
        fontSize: "41px",
        maxWidth: "60%",
        textAlign: "center",
        margin: "48px auto 40px ",
        fontFamily: "Raleway",
        LineHeight: '60px',
        color: "#2C2524",
        LetterSpacing: '0.5px'
    },
    "& .leftBorder": {
        backgroundColor: "#1A4A42",
        width: 32,
        height: '100vh',
        position: 'fixed',
        borderRadius: '0px 16px 16px 0px',
        top: 0,
        left: 0,
        zIndex: 99999,
    },
    "& .headerStepper": {
        "display": "flex",
        "justifyContent": "center",
    },
    "& .boxTitle": {
        fontWeight: 500,
        letterSpacing: '0.5px',
        "fontFamily": "Poppins",
        "textAlign": "center",
        marginBottom: '32px',
        "fontSize": "29px",
        width: "100%",
        "color": "#2C2524",
    },
    '& .chooseProvider': {
        display: "flex", flexDirection: "row", justifyContent: "Space-between", alignItems: 'center', marginBottom: '149px', paddingInline: "112px", position: "relative",
        "@media screen and (max-width:1100px)": {
            paddingInline: "50px !important"
        },
    },
    '& .SelectedInsurance': {
        marginBottom: '12px',
        fontWeight: 700,
        letterSpacing: '0.5px',
        fontFamily: "Poppins",
        textAlign: "center",
        fontSize: "20px",
        width: "100%",
        color: "#FFFFFF",
        backgroundColor: "#E46760",
        borderRadius: "24px",
        padding: "16px"
    },
    '& .unSelectedInsurance': {
        fontWeight: 700,
        letterSpacing: '0.5px',
        fontFamily: "Poppins",
        textAlign: "center",
        marginBottom: '12px',
        fontSize: "20px",
        width: "100%",
        color: "#78716C",
        border: "2px solid #D6D3D1",
        borderRadius: "24px",
        padding: "16px",
        backgroundColor: "#D6D3D1"
    },
    '& .underLinedText': {
        fontWeight: 700,
        "textAlign": "center",
        fontSize: "20px",
        color: "#807C7C",
        marginTop: "24px",
        textDecoration: "underline",
    },
    "& .footer": {
        minHeight: "96px",
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid #E7E5E4",
        alignItems: "center",
        "@media screen and (max-width:900px)": {
            paddingBottom: "12px",
            display: "block",
        },
    },
    "& .emergencyIcon": {
        alignItems: "center",
        display: "flex",
        fontFamily: "Poppins",
        fontWeight: "400",
        marginLeft: '141px',
        fontSize: "20px",
        marginRight: '459px',
        "@media screen and (max-width: 1550px)": {
            marginRight: '423px',
            marginLeft: '129px',
        },
        "@media screen and (max-width: 1500px)": {
            marginRight: '338px',
            marginLeft: '100px',
        },
        "@media screen and (max-width: 1400px)": {
            marginRight: '242px',
            marginLeft: '95px',
        },
        "@media screen and (max-width: 1250px)": {
            marginRight: '211px',
            marginLeft: '64px',
        },
        "@media screen and (max-width: 1200px)": {
            marginRight: '100px',
            marginLeft: '45px',
        },
        "@media screen and (max-width: 1100px)": {
            marginRight: '80px',
            marginLeft: '30px',
        },
        "@media screen and (max-width: 950px)": {
            marginRight: '35px',
            marginLeft: '15px',
        },
        "@media screen and (max-width: 900px)": {
            justifyContent: "center",
        },
        "@media screen and (max-width:500px)": {
            display: "block",
            textAlign: 'center',
        },
    },
    "& .footerRight": {
        display: "flex",
        marginRight: '141px',
        alignItems: "center",
        gap: "12px",
        "@media screen and (max-width:1500px)": {
            marginRight: '100px'
        },
        "@media screen and (max-width:1550px)": {
            marginRight: '129px'
        },
        "@media screen and (max-width:1400px)": {
            marginRight: '95px'
        },
        "@media screen and (max-width:1250px)": {
            marginRight: '64px'
        },
        "@media screen and (max-width:1200px)": {
            marginRight: '45px',
        },
        "@media screen and (max-width:1100px)": {
            marginRight: '30px',
        },
        "@media screen and (max-width:900px)": {
            justifyContent: "center",
        },
        "@media screen and (max-width:500px)": {
            textAlign: 'center',
            display: 'block'
        },
    }, "& .label-700-20": {
        fontWeight: 700,
        cursor: 'pointer',
        fontSize: "20px",
        fontFamily: "Poppins",
    },
    "& .callButton": {
        display: "flex",
        letterSpacing: "1px",
        gap: "8px",
        borderRadius: "16px",
        fontSize: "20px",
        border: "2px solid #E46760",
        padding: "10px 16px 10px 16px",
        color: "#E46760",
        alignItems: "center",
        fontFamily: "Raleway",
        fontWeight: 700,
        lineHeight: "36px",
        background: "#ffffff",
        "@media screen and (max-width:900px)": {
            padding: "6px"
        },
        "@media screen and (max-width:500px)": {
            margin: "auto"
        },
    },
})

const CustomButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    textTransform: 'none',
    fontSize: '20px',
    "&.call-button": {
        borderRadius: "16px",
        border: "2px solid #E46760",
        color: "#E46760",
        padding: "0 16px",
        height: "44px",
        gap: "8px",
        "@media screen and (max-width:700px)": {
            width: "158px",
            marginLeft: "-49px",
        },
        "@media screen and (max-width:500px)": {
            marginRight: "11px",
            padding: "0 10px",
        },
    },
    "&.call-button::hover": {
        color: "#E46760",
        border: "2px solid #E46760",
    },
}));


export default CareTeamInformation;