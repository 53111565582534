import React from "react";

import { Typography } from "@builder/component-library";

// Customizable Area Start
import {
  Box,
  styled,
  Paper,
  Stepper,
  StepLabel,
  StepConnector,
  Typography as CustomTypography,
  FormControlLabel,
  Chip,
  Stack
} from "@mui/material";
import {
  call,
  emergency,
  poor,
  skip,
  veryPoor,
  veryWell,
  well,
  neutral,
  checkedIcon,
  uncheckIcon
} from "./assets";
import {
  ResolveCondition,
  handleNavigation,
} from "../../../blocks/utilities/src/CustomHelpers";
import StepperData from '../../../components/src/StepperData.web';
import PageNotFound from "../../../components/src/PageNotFound.web";
import { ConditionalRenderer } from "./../../../blocks/utilities/src/CustomHelpers";
import ImageLoader from '../../../components/src/ImageLoader.web';

// Customizable Area End

import Cfcustomonboardingflowcreator1Controller, {
  Props,
  configJSON,
} from "./Cfcustomonboardingflowcreator1Controller";
import Loader from "../../../components/src/Loader";

export default class Cfcustomonboardingflowcreator1 extends Cfcustomonboardingflowcreator1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleButtonElm = (
    button_text: string,
    urlData: string,
    external_link: boolean,
    buttonType: string = ""
  ) => {
    const isDisabled = this.state.selectedAnswerBlockValue?.length === 0 &&
      this.state.selectedSingleColumnCheck?.length === 0 &&
      this.state.selectedTagCloudOptions?.length === 0 &&
      this.state.selectedThreeColumnCheck?.length === 0 &&
      this.state.selectedThreeColumnTile?.length === 0 &&
    this.state.selectedTagCloudOptions?.length === 0

    const buttonClass = buttonType ? "buttonSecond" : "button";
  if (isDisabled) {
    return <span className="disabled-button">{button_text}</span>;
  } 

    if (external_link) {
        return (
          <a
            className={buttonClass}
            data-test-id={button_text}
            href={urlData}
            rel="noopener noreferrer"
          >
            {button_text}
          </a>
        );
    } else {
      return (
        <button
          className={buttonClass}
          data-test-id={button_text}
          onClick={() => this.handleNavigation(urlData)}
        >
          {button_text}
        </button>
      );
    }
  };

  handleButtonElmSkipBtn = (
    button_text: string,
    urlData: string,
    external_link: boolean,
  ) => {
    const isDisabled = this.state.selectedAnswerBlockValue?.length === 0 &&
      this.state.selectedSingleColumnCheck?.length === 0 &&
      this.state.selectedTagCloudOptions?.length === 0 &&
      this.state.selectedThreeColumnCheck?.length === 0 &&
      this.state.selectedThreeColumnTile?.length === 0 &&
      this.state.selectedTagCloudOptions?.length === 0

  if (isDisabled) {
    return <div className="greyColor">
          <p
          className="skipIcon"
          data-test-id={button_text}
        >
          Skip this Step &nbsp;
          <span>
            <img src={skip} alt="skip" />
          </span>
        </p>
        </div>
  } 

    if (external_link) {
        return (
          <div>
                <a
                  className="greyColor"
                  href={urlData}
                  style={{textDecoration:"none"}}
                >
                 <p
                   className="skipIcon"
                   data-test-id={button_text}
                 >
                   Skip this Step &nbsp;
                   <span>
                     <img src={skip} alt="skip" />
                   </span>
                 </p>
                 </a>
               </div>
        );
    } else {
      return (
        <button
          className="skipIconBtn"
          data-test-id={button_text}
          onClick={() => this.handleNavigation(urlData)}
        >
          Skip this Step &nbsp;
                   <span>
                     <img src={skip} alt="skip" />
                   </span>
        </button>
      );
    }
  };

  renderSingleColumnButtons = () => {
    const { matchMakingRes, selectedAnswerBlockValue } = this.state;
  
    return (
      <Box className="divider">
      <Box className="answer-block">
        <Box>
          {matchMakingRes?.attributes?.match_making_sections?.single_column_buttons?.map((option: any) => {
            const otherLabels = ["Other", "other", "Others", "others", "Other answer"];
            const isOtherLabel = otherLabels.includes(option?.option_text);
    
            return (
              <React.Fragment key={option?.id}>
                <CustomCheckbox
                  data-test-id={option?.option_text}
                  selected={this.state.selectedSingleColumnCheck.includes(option?.id)}
                  onClick={() => this.handleSingleColumnCheckbox(option?.id, option?.option_text)}
                >
                  <CustomTypography className="option-label">
                    {option?.option_text}
                  </CustomTypography>
                </CustomCheckbox>
    
                {isOtherLabel && this.state.showOtherSingleColumnInput && (
                  <input
                    type="text"
                    value={this.state.otherValue}
                    onChange={this.handleOtherInputChange}
                    placeholder="Enter your option"
                    data-test-id="changeField"
                    className="inputFieldSingleColumn"
                  />
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
    
    );
  };  

  renderSingleColumnCheckbox = () => {
    const { matchMakingRes, selectedSingleColumnCheck } = this.state;

    return(
      <Box className="divider">
      <Box className="column single-column-checkbox">
        <Box className="boxOptions">
          {matchMakingRes?.attributes?.match_making_sections?.single_column_checkbox?.map((item) => {
            return (
              <>
                <Box className="option">
                  <img src={selectedSingleColumnCheck.includes(item?.id) ? checkedIcon : uncheckIcon} alt="icon" className="img"
                    data-test-id={item?.option_text}
                    onClick={() => {
                      this.handleSingleColumnCheckbox(item?.id, item?.option_text)
                    }} />
                  <CustomTypography className="optionLabel">{item?.option_text}</CustomTypography>
                </Box>
              </>
            )
          })}
          {this.state.showOtherSingleColumnInput && (
            <input
              type="text"
              value={this.state.otherValue}
              onChange={this.handleOtherInputChange}
              placeholder="Enter your option"
              data-test-id="changeField"
              className='inputField'
            />
          )}
        </Box>
      </Box>
    </Box>
    )
  }

  renderThreeColumnCheckbox = () => {
    const { matchMakingRes, selectedThreeColumnCheck } = this.state;

    return(
      <Box className="divider">
      <Box className="column three-column-checkbox">
        <Box className="mainContainer">
          <Box className="optionsGrid">
            {matchMakingRes?.attributes?.match_making_sections?.three_column_checkbox?.map((option) => (
              <Box key={option?.id} className="optionItem">
                <FormControlLabel
                  data-test-id={option?.option_text}
                  control={<img src={selectedThreeColumnCheck.includes(option?.id) ? checkedIcon : uncheckIcon} className="controlImage" />}
                  label={<CustomTypography className="optionLabel">{option?.option_text}</CustomTypography>}
                  className='lbl'
                  onClick={() => {
                    this.handleThreeColumnCheckbox(option?.id, option?.option_text)
                  }}
                />
      {this.state.showOtherThreeColumnInput && 
          ["Other", "other", "Others", "others"].includes(option?.option_text) && (
            <input
              type="text"
              value={this.state.otherValue}
              onChange={this.handleOtherInputChange}
              placeholder="Enter your option"
              data-test-id="changeField"
              className='inputField'
            />
        )}
         </Box>
       ))}
          </Box>
        </Box>
      </Box>
    </Box>
    )
  }

  renderSupportDesign = () => {
    const { matchMakingRes, selectedThreeColumnTile } = this.state;

    return(
      <Box className="divider">
      <Box className="column three-column-tile">
        <Box className="three-column-tile-container">
          <Box className="three-column-tile-box-Container">
            {
              matchMakingRes?.attributes?.match_making_sections?.support_design.map((item) => (
                <Box className={`boxes ${selectedThreeColumnTile.includes(item?.id) ? "active" : ""}`}>
                 {item?.image?.image_url && <img src={item?.image?.image_url} className="tile-images" alt="image" />
                 }
                  <CustomTypography className="tileTitle">{item?.title}</CustomTypography>
                  <CustomTypography className="tileDescription">{item?.body}</CustomTypography>
                 {item?.button_text &&
                  <button
                  data-test-id={'continuebtn'}
                  className={`tileBtn ${ResolveCondition(this.state.selectedThreeColumnTile.includes(item?.id), "active", "")}`} onClick={() => {
                    this.handleThreeColumnTiles(item?.id)
                  }}>{this.state.selectedThreeColumnTile.includes(item?.id) ? "Selected" : item?.button_text}</button> }
                 
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <Loader loading={this.state.loading} />

        {this.state.message ? <PageNotFound />:
        <section className="about-section">
          <Box className="headerStepper">
            <StepperData 
              key={`stepper${this.state.activeStep}`}
              listData={this.state.listData}
              activeStep={this.state.activeStep}
            />
          </Box>

          <Box className="main-section">
            <Box className="main-heading">
           {
           ConditionalRenderer(Boolean(this.state.matchMakingRes?.attributes?.main_title),
                    <CustomTypography style={{
                      fontFamily: "Raleway",
                      fontWeight: 600,
                      fontSize: 41,
                      letterSpacing: 0.5,
                      textAlign: "center",
                    }}>{this.state.matchMakingRes?.attributes?.main_title}</CustomTypography>, ''
                  )
                }
                 {
                  ConditionalRenderer(Boolean(this.state.matchMakingRes?.attributes?.sub_title), <CustomTypography className="information"
                  dangerouslySetInnerHTML={{__html: this.state.matchMakingRes?.attributes?.sub_title}}                    
                  />, "")
                } 
                {
                  ConditionalRenderer(Boolean(this.state.matchMakingRes?.attributes?.main_body), <CustomTypography className="choiceHeading"
                  dangerouslySetInnerHTML={{__html: this.state.matchMakingRes?.attributes?.main_body}}                    
                  />, "")
                } 
            </Box>
          </Box>

          {this.state.matchMakingRes?.attributes?.match_making_sections?.single_column_buttons && this.renderSingleColumnButtons()}

          {this.state.matchMakingRes?.attributes?.match_making_sections?.single_column_checkbox && this.renderSingleColumnCheckbox()}

          {this.state.matchMakingRes?.attributes?.match_making_sections?.three_column_checkbox && this.renderThreeColumnCheckbox()}

          {this.state.matchMakingRes?.attributes?.match_making_sections?.support_design && this.renderSupportDesign()}

          {this.state.matchMakingRes?.attributes?.match_making_sections?.tag_clouds &&
            <Box className="divider">
              <Box className="column tag-cloud-answer">
                <Box className="tag-cloud-container">
                  {this.state.matchMakingRes?.attributes?.match_making_sections?.tag_clouds.map((option) => {
                    return (
                      <Box key={option?.id} className="tag-cloud-option">
                        <Stack direction="row">
                          <Chip
                            size="medium"
                            data-test-id={option?.option_text}
                            label={
                              <CustomTypography className={`tag-cloud-label ${this.state.selectedTagCloudOptions.includes(option?.id) ? "active" : ""}`}>
                                {option?.option_text}
                              </CustomTypography>}
                            className={`tag-cloud-label ${this.state.selectedTagCloudOptions.includes(option?.id) ? "active" : ""}`}
                            onClick={() => {
                              this.handleTagCloudOption(option?.id)
                            }}
                          />
                        </Stack>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Box>
          }

<Box className="buttonFooter">
  <Box className="footer-container">
    <div className="box-wrapper1">
      <div
        className="greyColor"
        data-test-id="btnTherapy"
        onClick={() => {
          this.props.navigation.goBack();
        }}
      >
        <p>Back</p>
      </div>
    </div>

    {this.state.matchMakingRes?.attributes?.primary_links_section3_attributes.length === 0 ? (
      <div className="greyColor" style={{ marginLeft: "392px" }}>
        <p className="skipIcon" data-test-id="button_text">
          Skip this Step &nbsp;
          <span>
            <img src={skip} alt="skip" />
          </span>
        </p>
      </div>
    ) : (
      <>
      <div className="box-wrapper">
        {this.state.matchMakingRes?.attributes?.primary_links_section3_attributes.map((link, index) => (
          <div key={`primary-${index}`} style={{ 
            width: "100%",
            marginBottom: "20px"
          }}>
            {ConditionalRenderer(
              Boolean(link?.primary_button_text),
              this.handleButtonElm(link?.primary_button_text, link?.primary_link_url, link?.external_link, ""),
              ""
            )}
          </div>
        ))}
      </div>
        <div className="box-wrapper2">
          {this.handleButtonElmSkipBtn(
            this.state.matchMakingRes?.attributes?.primary_links_section3_attributes.slice(-1)[0]?.primary_button_text,
            this.state.matchMakingRes?.attributes?.primary_links_section3_attributes.slice(-1)[0]?.primary_link_url,
            this.state.matchMakingRes?.attributes?.primary_links_section3_attributes.slice(-1)[0]?.external_link
          )}
        </div>
      </>
    )}
  </Box>
</Box>

          {
              ConditionalRenderer(this.state.matchMakingRes?.attributes?.help_footer,
          <Box className="footer">
          <p className="emergencyIcon"><span><img src={emergency} alt="emergency" /></span>&nbsp; If this is an emergency &nbsp; <u>please call 911</u></p>
            <div className="footerRight" id="footer">
              <span className="label-700-20">Need Help?</span>
              <button className="callButton">
                Schedule a call?
                <span>
                  <img src={call} alt="call" />
                </span>
              </button>
            </div>
          </Box>,<Box className=""></Box>)
            }
        </section>}
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  width: "100%",
  margin: "0 auto",
  height: "100%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .subHead": {
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "17px",
  },
  "& .choiceHeading": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    margin: "32px auto",
    textAlign: "center"
  },
  "& .choiceHeading p": {
    margin: "0px",
    padding: "0px",
  },
  "& .column": {
    padding: "0px"
  },
  "& .boxOptions": {
    height: "auto",
    width: "100%",
    maxWidth: "324px",
    margin: "auto",
    marginBottom: '56px'
  },
  "& .option": {
    marginBottom: "12px",
    display: "flex"
  },
  "& .optionLabel": {
    fontSize: "20px",
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: "Poppins"
  },
  "& .img": {
    marginRight: "16px"
  },
  "& .inputField": {
    marginTop: "8px", 
    border: "1px solid #E6C79C",
    borderRadius: "24px",
    outline: "none",
    width: '324px',
    padding: '10px 16px',
    fontSize: '20px',
    fontFamily: 'Poppins',
    "&:focus": {
      border: "1px solid #E6C79C",
    }
  },
  "& .inputFieldSingleColumn": {
    border: "1px solid #E6C79C",
    borderRadius: "24px",
    outline: "none",
    width: '340px',
    padding: '10px 16px',
    fontSize: '20px',
    fontFamily: 'Poppins',
    "&:focus": {
      border: "1px solid #E6C79C",
    }
  },
  "& .mainContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // width: "95%",
    margin: "auto",
  },
"& .otherInputContainer": {
  gridColumn: "1 / -1", 
  marginTop: "16px", 
  },
  "& .optionsGrid": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: "20px", 
    marginLeft: '420px',
    alignItems: 'center',
    marginRight: '272px',
  },
  "& .optionItem": {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Poppins",
    fontWeight: 400,
    width: '316px',
    margin: '0px 48px 12px 48px',
  },
  "& .controlImage": {
    margin: "5px 16px 5px 0px",
  },
  "& .lbl": {
    display: "flex",
    alignItems: "center",
  },
  "& .three-column-tile-box-Container": {
    width: "100%",
    maxWidth: "912px",
    margin: "0px auto 56px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  "& .tile-images": {
    height: "72px",
    width: "72px",
    padding: '6px'
  },
  "& .tileTitle": {
    width: "auto",
    textAlign: "center",
    height: "auto",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    paddingTop: "12px",
  },
  "& .tileDescription": {
    height: "100%",
    margin: "12px",
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#807C7C"
  },
  "& .tileBtn.active": {
    width: "184px",
    padding: "6px 10px",
    color: "#FFFFFF",
    border: "1px solid #E46760",
    borderRadius: "16px",
    flexShrink: 0,
    background: "#E46760",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Poppins"
  },
  "& .tileBtn": {
    cursor: 'pointer',
    width: "184px",
    padding: "6px 10px",
    height: "auto",
    color: "#E46760",
    border: "1px solid #E46760",
    borderRadius: "16px",
    background: "transparent",
    flexShrink: 0
  },
  "& .boxes": {
    flexGrow: 1,
    flexBasis: "calc(33.33% - 20px)",
    minWidth: "250px",
    margin: "10px",
    border: "1px solid #D6D3D1",
    borderRadius: "16px",
    justifyContent: "space-between",
    padding: "16px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .boxes.active": {
    border: "2px solid #E46760",
  },
  "& .tag-cloud-container": {
    width: "95%",
    maxWidth: "808px",
    display: "flex",
    flexWrap: "wrap",
    margin: "auto",
    justifyContent: "center"
  },
  "& .tag-cloud-option": {
    flex: "0 0 auto",
    width: "auto",
    marginBottom: "10px",
    marginRight: "6px"
  },
  "& .tag-cloud-label": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#44403C",
  },
  "& .tag-cloud-label.active": {
    background: "#E46760",
    color: "#FFFFFF"
  },
  "& .footerRight": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginRight: '141px',
    "@media screen and (max-width:1500px)": {
      marginRight: '100px',
      },
    "@media screen and (max-width:1550px)": {
      marginRight: '129px',
      },
    "@media screen and (max-width:1400px)": {
      marginRight: '95px',
      },
    "@media screen and (max-width:1250px)": {
      marginRight: '64px',
      },
    "@media screen and (max-width:1200px)": {
      marginRight: '45px'
      },
    "@media screen and (max-width:1100px)": {
      marginRight: '30px'
      }, 
    "@media screen and (max-width:900px)": {
      justifyContent: "center"
      },
      "@media screen and (max-width:500px)": {
        textAlign: 'center',
        display: 'block'
      },
  },
  "& .label-700-20": {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  "& .main-heading": {
    padding: "60px 0px 30px"
  },
  "& .divider": {
    borderBottom: "1px solid #D6D3D1"
  },
  "& .main-section": {
    textAlign: "center",
  },
  "& .custom-step-connector": {
    width: "100%",
    maxWidth: "600px",
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42",
    backgroundColor: "#FFFAF6"
  },
  "& .question": {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
  },
  "& .information": {
    maxWidth: "805px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
  },
  "& .information p": {
    margin: "32px 0px",
    padding: "0px",
  },
  "& .buttonSecond": {
    width: "max-content",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    margin: "13px auto 0",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    color: "#E46760",
    border: "none",
    background: "none",
    cursor: "pointer",
  },
  "& .answer-block": {
    display: "flex",
    flexDirection: "column",
    width: "700px",
    gap: "15px",
    alignItems: "center",
    marginTop: "32px",
    margin: "auto",
    padding: "32px",
    "@media screen and (max-width:700px)": {
      width: "470.5px",
    },
    "@media screen and (max-width:500px)": {
      width: "0px",
    },
  },
  "& .box-wrapper": {
    width: "100%",
  },
"& .box-wrapper1": {
  marginRight: "368.5px",
  "@media screen and (max-width:1500px)": {
    marginRight: "308.5px",
  },
  "@media screen and (max-width:1200px)": {
    marginRight: "228.5px",
  },
  "@media screen and (max-width:1400px)": {
    marginRight: "268.5px",
  },
  "@media screen and (max-width:950px)": {
    marginRight: "208.5px",
  },
  "@media screen and (max-width:900px)": {
    textAlign: "center",
    marginRight: "0px"
  },
  },
   "& .box-wrapper2": {
    marginLeft: "244.5px",
    "@media screen and (max-width:1500px)": {
      marginLeft: "184.5px",
      },
    "@media screen and (max-width:1400px)": {
      marginLeft: "144.5px",
      },
      "@media screen and (max-width:1200px)": {
        marginLeft: "104.5px",
        },
    "@media screen and (max-width:950px)": {
        marginLeft: "84.5px",
     },
    "@media screen and (max-width:900px)": {
      textAlign: "center",
      marginLeft: "0px"
    },
  },
  "& .headerStepper": {
    "display": "flex",
    "justifyContent": "center",
  },
  "& .callButton": {
    background: "#ffffff",
    borderRadius: "16px",
    border: "2px solid #E46760",
    padding: "10px 16px 10px 16px",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "1px",
    "@media screen and (max-width:900px)": {
      padding: "6px",
      },
      "@media screen and (max-width:500px)": {
        margin: "auto",
        },
  },

  "& .footer": {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #E7E5E4",
    alignItems: "center",
    minHeight: "96px",
    "@media screen and (max-width:900px)": {
      display: "block",
      paddingBottom: "12px"
    },
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "black",
  },
  "& .skipIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    textWrap: "noWrap",
  },
  "& .skipIconBtn": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    textWrap: "noWrap",
    color: "#807C7C",
    cursor: "pointer",
    background: "none",
    border: "none",
    paddingTop: "19px",
    paddingBottom: "19px"
  },
"& .emergencyIcon": {
  display: "flex",
  alignItems: "center",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "20px",
  marginRight: '459px',
  marginLeft: '141px',
  "@media screen and (max-width: 1550px)": {
    marginRight: '423px',
    marginLeft: '129px'
  },
  "@media screen and (max-width: 1500px)": {
    marginRight: '338px',
    marginLeft: '100px'
  },
  "@media screen and (max-width: 1400px)": {
    marginRight: '242px',
    marginLeft: '95px'
  },
  "@media screen and (max-width: 1250px)": {
    marginRight: '211px',
    marginLeft: '64px'
  },
  "@media screen and (max-width: 1200px)": {
    marginRight: '100px',
    marginLeft: '45px'
  },
  "@media screen and (max-width: 1100px)": {
    marginRight: '80px',
    marginLeft: '30px'
  },
  "@media screen and (max-width: 950px)": {
    marginRight: '35px',
    marginLeft: '15px'
  },
  "@media screen and (max-width: 900px)": {
    justifyContent: "center"
  },
  "@media screen and (max-width:500px)": {
    textAlign: 'center',
    display: "block"
  },
},
  "& .footer-container": {
    display: "flex",
    "@media screen and (max-width: 900px)": {
      display: "block"
    },
    "@media screen and (max-width:700px)": {
      marginRight: '97px',
    },
    "@media screen and (max-width:600px)": {
      marginRight: '150px',
    },
    "@media screen and (max-width:550px)": {
      marginRight: '210px',
    },
    "@media screen and (max-width:500px)": {
      marginRight: '245px',
    },
    "@media screen and (max-width:450px)": {
      marginRight: '300px',
    },
    "@media screen and (max-width:400px)": {
      marginRight: '355px',
    },
  },
  "& .buttonFooter": {
    display: "flex",
    flexDirection: "column",
    width: "700px",
    gap: "15px",
    alignItems: "center",
    margin: "auto",
    paddingTop: '70px',
    paddingBottom: '59px',
  },
  "& .button-continue": {
    height: "56px",
    width: "100%",
    maxWidth: "392px",
    borderRadius: "auto",
    background: "#E46760",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
    textDecoration: "none"
  },
  "& .button-continue.active": {
    background: "#E46760",
    color: "#FFFFFF",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
  },
  "& .button-continue.inactive": {
    background: "#D6D3D1",
    color: "#FFFFFF",
    cursor: "not-allowed",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
  },
  "& .greyColor": {
    color: "#807C7C",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    cursor: "pointer",
  },
  "& u": {
    color: "#D97706",
  },
  "& .header": {
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    minHeight: "140px",
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .button": {
    width: "392px",
    borderRadius: "24px",
    backgroundColor: "#E46760",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    padding: "16px 20px 16px 20px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    cursor: "pointer",
    textDecoration: "none",
    letterSpacing: "1px",
      "@media screen and (max-width:1200px)": {
        width: "300px",
        },
  },
  "& .disabled-button": {
    width: "392px",
    borderRadius: "24px",
    background: "#D6D3D1",
    color: "#FFFFFF",
    cursor: "not-allowed",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    padding: "16px 20px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    "@media screen and (max-width:1500px)": {
      width: "400px",
      },
    "@media screen and (max-width:1400px)": {
      width: "400px",
      },
    "@media screen and (max-width:1200px)": {
      width: "360px",
      },
    "@media screen and (max-width:1100px)": {
      width: "300px",
    },
    "@media screen and (max-width:900px)": {
      width: "242px",
    },
  },
  "& .headeingWellness": {
    fontFamily: "Raleway",
    color: "#1A4A42",
    fontWeight: 800,
    fontSize: "64px",
    lineHeight: "76.8px",
    letterSpacing: "0.5px",
    textAlign: "center",
    margin: "56px 0 16px",
  },
 
  "& .list": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    display: "flex",
    justifyContent: "center",
    color: "#2C2524",
  },
  "& .image": {
    height: "auto",
    width: "100%",
    objectFit: "contain",
  },
  "& .content": {
    position: "absolute",
    bottom: "0",
    transform: "translate(-50% ,0)",
    width: "100%",
    maxWidth: "730px",
    left: "50%",
  },
});


const CustomCheckbox = styled(Paper)<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: "4px 16px 4px 12px",
  marginBottom: "12px",
  cursor: 'pointer',
  boxShadow: "none",
  gap: "8px",
  backgroundColor: ResolveCondition(selected, "#E46760", "#E7E5E4") as string,
  '&:hover': {
    backgroundColor: ResolveCondition(selected, "#E46760", "#E46760") as string,
  },
  borderRadius: "26px",
  width: "340px",
  height: "auto",
  "& .icons": {
    color: ResolveCondition(selected, 'white', '#44403C'),
    fontWeight: ResolveCondition(selected, '700', '400'),
  },
  "& .option-label": {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: ResolveCondition(selected, 'white', '#44403C'),
    fontWeight: ResolveCondition(selected, '700', '400'),
    padding: '9px 16px'
  },
}));


// Customizable Area End
