// Customizable Area Start
import { createRef, RefObject } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    id: string;
    classes: Object;
    navigation: any;
}

interface S {
    toggleMenu: boolean,
    selectedMenu: string,
    button_text: string,
    menuList: string[],
    showModal: boolean,
    messageText: string,
    file: any
}

interface SS {
    id: string;
}

export default class NeedHelpController extends BlockComponent<
    Props,
    S,
    SS
> {
    getNeedHelpCallId: string = "";
    needHelpPostApiCallID: string = "";
    dropDownRef: RefObject<HTMLDivElement> | null;
    uploadRef: RefObject<HTMLInputElement> | null;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            toggleMenu: false,
            file: [],
            selectedMenu: '',
            messageText: '',
            button_text: 'New Chat',
            menuList: [
                "Any time", 
                "Morning: 8 - 12 PM",
                "Afternoon: 12 - 4 PM",
                "Evening: 4 - 8 PM"
            ],
            showModal: false,
        };
        this.uploadRef = createRef();
        this.dropDownRef = createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleSelectData = (data: string) => {
        this.setState({ selectedMenu: data,
                        toggleMenu: false
        })
    }

    handleMessage = (data: string) => {
        this.setState({
            messageText: data
        })
    }

    async componentDidMount() {
        document.title = `Need Help || TotalLifePlatform`;
    }

    async componentDidUpdate() {
        document.title = `Need Help || TotalLifePlatform`;
      }

    handleFileChange = (event: any) => {
        try {
            const file = event.target.files[0];
            if (file) {
                this.setState({ messageText: file.name });
            }
        } catch (error) {
            console.error("Error handling file input:", error);
        }
    };

    handleChat = () => {
        this.setState({
            showModal: true
        });
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false
        });
    }

    toggleMenuData = () => {
        this.setState({
            toggleMenu: !this.state.toggleMenu
        })
    }
}

// Customizable Area End