// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    Typography,
    Button,
    IconButton,
    TextField,
    InputLabel
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { uploadMediaIcon, sendIcon, supportAgentIcon, videoSession, videoIcon, microphone, chatBubble, fullScreen, moreBtn } from './assets';
import ScheduledSessionController, {
    Props,
} from "./ScheduledSessionController";
import ImageLoader from '../../../components/src/ImageLoader.web';
import { handleNavigation } from '../../../blocks/utilities/src/CustomHelpers';
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';

class ScheduledSession extends ScheduledSessionController {
    constructor(props: Props) {
        super(props);
    }

    handleDisabledBtn = (data: string | boolean) => {
        return data ? false: true
    }

    render() {
        return (
            <StyledWrapper>
                <section
                style={{
                    borderLeft: "32px solid #1A4A42",
                  }}>
                         <Box className="header-section">
                        <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                        </Box>
                                    {
                    this.state.isQueue ? 
                <Box>
                    <Box sx={{ 
                    background: '#FFFAF6', 
                    borderTop: '1px solid #D6D3D1',
                    padding: '16px',
                }}>
                    <Box className='main-content'>
                    <Typography className='main-title'>Please Standby</Typography>
                    <Typography className='sub-title'>Your receptionist will be right with you</Typography>
                    </Box>
                    <Box className="mainContent">
                        <Box className="imageContent">
                            <img src={supportAgentIcon} alt="Image"/>
                        </Box>
                        <Box >
                            <Box className="subContent">
                            <Typography variant="h6" className="sub-text"> You are <span className="queue-position">12th</span> in the queue</Typography>
                            <Box>
                            <ImageLoader loading={true} session={true}/>
                            </Box>
                            </Box>
                           <Typography className='warning-text'>The Video-call will start Automatically</Typography>
                        </Box>
                        <Box></Box>
                    </Box>
                    <Box className="video-content">
                        <Box className='video-image' >
                        <img src={videoSession} alt="Image"/>
                        </Box>
                        <button className="video-text">Test Speaker and Mic</button>
                    </Box>
                    <Box>
                        <Typography className='exitText'
                        onClick={()=>handleNavigation('needHelp',this.props)}
                         style={{
                            marginLeft: '231.5px'
                        }}>Exit the queue</Typography>
                    </Box>
                </Box>
                </Box>
                :
                <Box style={{
                    background: '#FFFAF6',
                    borderTop: "1px solid #D6D3D1",
                    }}>
                <Box>
                <Box className='main-content'>
                    <Typography className='main-title'>Your session is Live</Typography>
                    <Typography className='sub-title'>If you are having trouble connecting, please call the phone number:  
                        <span style={{
                            fontWeight: 700,
                            marginLeft: '5px'
                        }}>  1-800-567-LIFE</span></Typography>
                    </Box>
                    <Box className="live-content">
                        <Box display="flex" justifyContent="center">
                        <Box className='live-image' style={{
                            borderRadius: '24px'
                        }} >
                        <img src={videoSession} alt="Image" className='liveImage'/>
                        </Box>
                        <Box className="chat-container" sx={{
                            marginLeft: '24px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <Typography
                                className='headingTextData'
                                dangerouslySetInnerHTML={{__html: "Session Chat"}}
                            />
                             <IconButton
                                aria-label="close"
                                data-test-id="close-modal"
                                // onClick={() => this.handleCloseModal()}
                                sx={{
                                    marginTop: '17px',
                                    height: '24px',
                                    width: '24px',
                                    marginRight: '17px',
                                    color: '#807C7C'
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            </Box>
                            <Box style={{ padding: "0 12px", gap: "10px", display: "flex", flexDirection: "column" }}>
                                <Box className="reply-section">
                                    <Typography><span className='nameField'>Total Life:</span><span className='time-field'>14:37</span></Typography>
                                    <Typography className='bodyElm'>Hi there! I'm here to help.What can I assist you with today?</Typography>
                                </Box>
                                <Box className="reply-section client">
                                    <Typography><span className='nameField client'>You:</span><span className='time-field'>14:38</span></Typography>
                                    <Typography className='bodyElm client'>I need some help with...</Typography>
                                </Box>
                            </Box>
                            <Box className="footer-elm">
                                <CustomTextField
                                    multiline
                                    data-test-id="message-field"
                                    value={this.state.messageText}
                                    onChange={(event) => this.handleMessage(event.target.value)}
                                    placeholder='Type here'
                                />
                                <Box className="right-section">
                                <InputLabel>
                                    <img src={uploadMediaIcon} alt="upload media" width={32} height={32} />
                                    <input
                                        ref={this.uploadRef}
                                        onChange={(event) => this.handleFile(event)}
                                        style={{ display: "none" }}
                                        data-test-id="file-field"
                                        type="file"
                                    />
                                </InputLabel>
                                    <CustomButton data-test-id="button-elm" onClick={() => {}} disabled={this.handleDisabledBtn(this.state.messageText)} className="send-button">
                                        <img src={sendIcon} alt="send btn" />
                                    </CustomButton>
                                </Box>
                            </Box>
                        </Box>
                        </Box>
                    <Box display="flex" justifyContent="center" sx={{mt:2}}>
                        <IconButton aria-label="video call" color="primary" sx={{
                                backgroundColor: '#E46760',
                                borderRadius: '16px',
                                marginRight: '24px',
                                padding: '0px',
                                '&:hover': {
                                    backgroundColor: '#d4554c', 
                                }
                            }}>
                            <img src={videoIcon} alt="video call" 
                            style={{ 
                                display: 'block', 
                                width: '30px', 
                                height: '30px', 
                                padding: '10px 15px'
                            }} />
                        </IconButton>
                    <IconButton aria-label="microphone"
                    sx={{
                        marginRight: '24px',
                        backgroundColor: '#E46760',
                        borderRadius: '16px',
                        color:"white" ,
                        '&:hover': {
                            backgroundColor: '#d4554c', 
                        }
                    }}>
                    <img src={microphone} style={{ 
                                display: 'block', 
                                width: '24px', 
                                height: '24px', 
                                padding: '10px'
                            }}
                        alt="video call" 
                        />
                    </IconButton>
                    <IconButton aria-label="chat" sx={{
                                backgroundColor: '#E7E5E4',
                                borderRadius: '16px',
                                marginRight: '24px',
                                '&:hover': {
                                    backgroundColor: '#d4554c', 
                                }
                            }}>
                    <img src={chatBubble} alt="video call" style={{ 
                                display: 'block', 
                                width: '24px', 
                                height: '24px', 
                                padding: '10px'
                            }}/>
                    </IconButton>
                <IconButton aria-label="screen share" sx={{
                                backgroundColor: '#E7E5E4',
                                borderRadius: '16px',
                                marginRight: '24px',
                                '&:hover': {
                                    backgroundColor: '#d4554c', 
                                }
                            }}>
                <img src={fullScreen} alt="video call" 
                style={{ 
                                display: 'block', 
                                width: '24px', 
                                height: '24px', 
                                padding: '10px'
                            }}/>
                </IconButton>
                <IconButton aria-label="more options" sx={{
                                backgroundColor: '#E7E5E4',
                                borderRadius: '16px',
                                marginRight: '24px',
                                '&:hover': {
                                    backgroundColor: '#d4554c', 
                                }
                            }}>
                    <img src={moreBtn} alt="more" 
                    style={{ 
                        display: 'block', 
                        width: '5px', 
                        height: '23px', 
                        padding: '10px 19px'
                    }}/>
                   </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <Typography 
                        onClick={()=>handleNavigation('needHelp',this.props)}
                        className='exitText'>Exit session</Typography>
                    </Box>
                </Box>
                </Box>
                }
                </section>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    '& .exitText': {
        marginLeft: '205px',
        color: '#78716C',
        fontSize: '20px',
        fontWeight: 700,
        fontFamily: 'Raleway',
        paddingBottom: '124.5px',
        cursor: 'pointer'
    },
    "& .header-section": {
        height: "78px",
        display: "flex",
        alignItems: "center",
        background: 'white',
        width: "100%",
        '& img': { paddingLeft: "3%" },
    },
    "& .subContent": {
        alignItems: "center",
        background: "#F5F5F4",
        borderRadius: "32px",
        display: 'flex',
        padding: '16px 41px'
    },
    "& .chat-container": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        borderRadius: "16px",
        border: "1px solid #D6D3D1",
        width: '334px',
        height: '402px',
        background: 'white',
        "& .headingTextData": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            padding: "0px 12px",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#000"
        }
    },
    "& .footer-elm": {
        height: "68px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: '102px',
        borderTop: "1px solid #D6D3D1"
    },
    "& .right-section": {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        "& .send-button": {
            width: "40px",
            height: "40px",
            borderRadius: "6.15px",
            backgroundColor: "#1A4A42",
            minWidth: "20px",
            marginRight: '12px'
        },
        '& .Mui-disabled.send-button': {
            opacity: 0.4
        }
    },
    "& .reply-section": {
        maxWidth: "263px",
        flexDirection: "column",
        gap: "4px",
        padding: "6px",
        borderRadius: "8px",
        backgroundColor: "#E8EDEC",
        display: "flex",
        "&.client": {
            maxWidth: "265px",
            backgroundColor: "transparent",
            border: "1px solid #E46760",
            alignSelf: "end",
        },
        "& .nameField, & .time-field": {
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "19.5px",
        },
        "& .time-field": {
            color: "#807C7C"
        },
        "& .bodyElm": {
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#44403C",
            "&.client": {
                color: "#57534E"
            }
        },
        "& .nameField": {
            fontStyle: "italic",
            color: "#1A4A42",
            marginRight: "8px",
            "&.client": {
                backgroundImage: `linear-gradient(0deg, #2C2524, #2C2524),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
                color: "transparent",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textFillColor: "transparent",
                fontStyle: "normal",
                backgroundClip: "text",
                fontWeight: 700
            }
        }
    },
    "& .queue-position": {
        fontWeight: 700,
    },
    '& .sub-text': {
        width: '144px',
        color: "#1A4A42",
        font_size: "24px",
        fontWeight: 500,
        fontFamily: "Poppins",
        marginRight: '24px'
    },
    '& .warning-text' : {
        fontWeight: 400,
        fontFamily: "Poppins",
        font_size: "16px",
        color: '#44403C',
        marginLeft: '5px'
    },
    "& .logoImg": {
        width: "162px",
        height: "41.24px"
    },
    '& .main-title': {
        fontSize: '35px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '89px'
    },
    '& .sub-title': {
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
        color: '#44403C'
    },
    '& .main-content': {
        justifyContent: 'center',
    },
    '& .mainContent': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '60px',
    },
    '& .imageContent': {
        width: '96px',
        height: '96px',
        alignContent: 'end'
    },
    '& .video-content': {
        marginTop: '40px',
        marginBottom: '43.5px',
        display: 'grid'
    },
    '& .video-image': {
        display: 'flex',
        justifyContent: 'center',
    },
    '& .video-image img': {
        width: '480px',
        borderRadius: '24px 24px 0px 0px'
    },
    '& .video-text': {
        fontFamily: 'Poppins',
        fontWeight: 700,
        borderRadius: '0px 0px 16px 16px',
        width: '480px',
        margin:'auto',
        background: '#1A4A42',
        border: 'none',
        color: 'white',
        padding: '6px',
        textDecoration: 'underline',
        fontSize: '16px'
    },
    '& .live-image': {
        justifyContent: 'center',
    },
    '& .liveImage': {
        width: '657px',
        height: '402px',
        borderRadius: '24px'
    },
    'iconStyles': {
        padding:'10px',
        background:'#E46760',
    },
    '& .live-content': {
        margin: '40px auto 31.5px',
        padding: '24px',
        background: 'black',
        borderRadius: '32px',
        width: '1016px',
        gap: '24px',
        height: 'auto',
    },
});
const CustomButton = styled(Button)(({ theme }) => ({
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    textTransform: 'none',
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    borderRadius: '24px',
    height: "56px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    
    '&.submit-button, &.confirm-button': {
        backgroundColor: '#E46760',
        width: "100%",
        margin: "40px auto 0",
        fontFamily: 'Raleway',
        color: '#FFFAF6',
        fontWeight: 700,
        fontSize: '20px'
    },
    '&.back-button': {
        backgroundColor: 'transparent',
        color: '#78716C',
        width: "max-content",
        height: "max-content",
        marginLeft: "389.5px",
        marginBottom: "124px"
    },
    '&.confirm-button': {
        width: "100%",
        marginTop: "0",
    },
    '&.call-button': {
        backgroundColor: '#F5F5F4',
        color: '#2C2524',
        width: "100%",
    },
    '&.Mui-disabled.submit-button, &.Mui-disabled.confirm-button': {
        backgroundColor: '#E7E5E4',
        color: "#A8A29E",
        cursor: 'not-allowed',
        boxShadow: 'none',
        pointerEvents: 'none',
    },
    '&.submit-button:hover': {
        backgroundColor: '#E46760',
    },
}))

const CustomTextField = styled(TextField)(({
    width: "88%",
    overflow: "auto",
    MsoverflowStyle: "none",
    scrollbarWidth: "none",
    '&::-webkit-scrollbar': {
        display: "none"
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      height: "44px",
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiInputBase-input': {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        padding: '12px',
        color: "#2C2524",
        '&::placeholder': {
            color: "#A8A29E",
            opacity: 1
        }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        display: "flex",
        alignItems: "center",
        border: "1px solid transparent"
      },
      '&:hover fieldset': {
        border: "1px solid transparent"
      },
      '&.Mui-focused fieldset': {
        border: "1px solid transparent"
      },
    },
}));

export default ScheduledSession;
// Customizable Area End