import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props{
  navigation: any;
  id: string; 
}

export interface S{
  listData: {progress_bar_text: string, progress_bar_stage: string}[]
  activeStep: string,
  openSupplementModal: boolean
  supplementInsurance: boolean;
  selectedPlan: string
  firstName: string;
  lastName: string;
  memberId: string;
  zipCode: string;
}

interface SS {
    id: any;
  }

export default class InsuranceVerifyController extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

    this.state = {    
        openSupplementModal: false,  
      listData: [
        { progress_bar_text: 'About You', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Verify Insurance', progress_bar_stage: '2' },
        { progress_bar_text: 'Choose Provider',  progress_bar_stage: '3'}
      ],
      activeStep: 'Verify Insurance',
      supplementInsurance: false ,
      selectedPlan: 'Select a Plan',
      firstName: '', 
      lastName: '',
      memberId: '',
      zipCode: ''
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);        
      } 

      async componentDidMount() {
        document.title = `Insurance Verify || TotalLifePlatform`;
      }

      async componentDidUpdate() {
        document.title = `Insurance Verify || TotalLifePlatform`;
      }

    handleDropdownChange = (event: { target: { value: string; }; }) => {
        this.setState({ selectedPlan: event.target.value });
    };

    handleFirstNameChange = (event: { target: { value: string; }; }) => {
        this.setState({ firstName: event.target.value });
    };

    handleLastNameChange  = (event: { target: { value: string; }; }) => {
        this.setState({ lastName: event.target.value });
    };

    handleMemberIdChange = (event: { target: { value: string; }; }) => {
        this.setState({ memberId: event.target.value });
    };

    handlezipCodeChange= (event: { target: { value: string; }; }) => {
        this.setState({ zipCode: event.target.value });
    };
}