import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: Object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    steps: string[],
    selectedTab: number,
    activeStep: number,
    inputValue: string,
    providerPreference: boolean,
    ShowSuccessModal: boolean
    schedulingPreference: { label: string, checked: boolean, value: number }[],
    allChecked: boolean,
    startTime: string[],
    endTime: string[]

    // Customizable Area End
}

interface SS {
    id: any;
}

export default class JoinWaitListController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            startTime: ["", "", "", "", "", "", ""],
            endTime: ["", "", "", "", "", "", ""],
            inputValue: "",
            providerPreference: true,
            selectedTab: 0,
            steps: ["About You", "Verify Insurance", "Choose Provider"],
            activeStep: 2,
            ShowSuccessModal: false,
            schedulingPreference: [{ label: "Monday", checked: false, value: 1 }, { label: "Tuesday", checked: false, value: 2 }, { label: "Wednesday", checked: false, value: 3 }, { label: "Thrusday", checked: false, value: 4 }, { label: "Friday", checked: false, value: 5 }, { label: "Saturday", checked: false, value: 6 }, { label: "Sunday", checked: false, value: 7 }],
            allChecked: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }

    // Customizable Area Start    

    handleChange = (event: any) => {
        this.setState({ inputValue: event.target.value });
    };

    goBack = () => {
        if (this.state.providerPreference === false) {
            this.setState({ providerPreference: true })
        }
        else { this.props.navigation.goBack() }
    };

    handleNextClick = () => {
        if (this.state.providerPreference && this.state.providerPreference === true) {
            this.setState({ providerPreference: false })
        }
        else {
            this.setState({ ShowSuccessModal: true, activeStep: 3 })
        }
    };
    handleCheckboxChange = (e: any, Index: any) => {
        this.setState(prevState => {
            const updatedSchedulingPreferences = prevState.schedulingPreference.map((item, index) => {
                if (index === Index) {
                    return { ...item, checked: e.target.checked };
                }
                return item;
            });
            this.setState({ schedulingPreference: updatedSchedulingPreferences }, () => { this.setState({ allChecked: this.state.schedulingPreference.every(checked => checked.checked) }) });
            return { schedulingPreference: updatedSchedulingPreferences };
        });
    };

    handleAllTimeCheckBox = () => {
        if (this.state.schedulingPreference.every(checked => checked.checked)) {
            this.setState({ allChecked: false })
            this.setState(prevState => ({ schedulingPreference: prevState.schedulingPreference.map(item => ({ ...item, checked: false })) }));
        }
        else {
            this.setState({ allChecked: true });
            this.setState(prevState => ({ schedulingPreference: prevState.schedulingPreference.map(item => ({ ...item, checked: true })) }));

        }
    };

    handleStartTimeChange = (dayIndex: number, newStartTime: string | null): void => {
        const updatedStartTimes = [...this.state.startTime];
        updatedStartTimes[dayIndex] = newStartTime || '';
        this.setState({ startTime: updatedStartTimes });
    };

    // Handle end time change for a particular day
    handleEndTimeChange = (dayIndex: number, newEndTime: string | null): void => {
        const updatedEndTimes = [...this.state.endTime];
        updatedEndTimes[dayIndex] = newEndTime || '';
        this.setState({ endTime: updatedEndTimes });
    };

    closeModal = () => { this.setState({ ShowSuccessModal: false, activeStep: 2 }) }

    // Customizable Area End
}
