// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    Typography,
    FormControl,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import StepperData from '../../../components/src/StepperData.web';
import { HeaderLogoImg } from './assets';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CareTeamController, {
    Props,
} from "./CareTeamController";
import { ResolveCondition } from '../../../blocks/utilities/src/CustomHelpers';

class CareTeam extends CareTeamController {
    constructor(props: Props) {
        super(props);
    }

    render() {
    const IconComponent = this.state?.open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
        return (
            <StyledWrapper>
                <Box className="container-elm">
                    <Box className="header-section">
                        <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                    </Box>
                    <Box className="headerStepper">
                            <StepperData
                                key={`stepper${this.state.activeStep}`}
                                listData={this.state.listData}
                                activeStep={this.state.activeStep}
                            />
                        </Box>
                    <Box className="content-container">
                        <Typography
                            className='headingText'
                            dangerouslySetInnerHTML={{__html: "Let's Design Your Care Team!"}}
                        />
                        <Typography
                            className='subText'
                            dangerouslySetInnerHTML={{__html: "Where do you live?"}}
                        />
                        <Typography
                            className='bodyText'
                            dangerouslySetInnerHTML={{__html: "Knowing your location lets us match you with clinicians licensed in your state."}}
                        />
                    </Box>  
                    <Box className="box">
                    <Box className="div-container">
                  <div className="heading">Select your current State
                  </div>
                    <FormControl className="inputFieldLast1" fullWidth>
                        <Select
                        defaultValue=""
                        placeholder="Select your State"
                        value={this.state.dropDownState|| ''}
                        name="dropdown"
                        data-test-id="stateID"
                        MenuProps={{
                            PaperProps: {
                            sx: {
                                border: "1px solid #E6C79C",
                                borderRadius: "0px 0px 24px 24px",
                            },
                            },
                        }}
                        displayEmpty
                        sx={{
                            padding: "12px",
                            borderRadius: "0px 0px 24px 24px",
                            borderTop: "none",
                            border: "1px solid #E6C79C",
                            "& fieldset" : {
                            border: "none"
                            },
                            "& .MuiSelect-select": {
                                borderRadius: "16px",
                                fontFamily: "Poppins", 
                                fontSize: "16px", 
                                lineHeight: "24px", 
                                fontWeight: 400, 
                                color: "##2C2524", 
                                },
                        }}
                        IconComponent={IconComponent}
                        onChange={(e) =>this.handleStateChange(e)}
                        >
                            <MenuItem value='' disabled>Select your state</MenuItem>
                        {this.state.stateDropdown.map((item: string, index: number)=>(
                            <MenuItem key={index}  value={item}
                            sx={{borderBottom: "1px solid #D6D3D1",
                                padding: "8px 12px 8px 16px",
                                fontWeight: 400,
                                height: "40px",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                lineHeight: "24px",
                                  "& .MuiSelect-select": {
                            borderRadius: "16px",
                            fontFamily: "Poppins", 
                            fontSize: "16px", 
                            lineHeight: "24px", 
                            fontWeight: 400, 
                            color: "##2C2524", 
                            },
                                "&:hover": {
                                backgroundColor: "#e0e0e0",
                                },
                            }}
                            >
                            {item}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>        
                    <Typography style={{
                        fontSize: '13px',
                        fontFamily: "poppins",
                        fontWeight: 400,
                        color:'#78716C',
                        lineHeight: '19.5px'
                    }}>if you are a snowbird select the 
                        state that you will be attending sessions from</Typography>   
                    </Box>
                    <Box className="div-container" >
                    <FormControl>
                    <label className="heading" style={{
                        marginBottom: '8px'
                    }}>Do you prefer video sessions or phone calls?</label>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            value={this.state.selectedRadioValue} 
                            onChange={this.handleRadioChange}
                        >
                            <FormControlLabel value="Video sessions" control={<Radio />} label={
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
                                  Video sessions
                                </Typography>
                              }/>
                            <FormControlLabel value="Phone Calls" control={<Radio />} 
                             label={
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
                                  Phone Calls
                                </Typography>
                              } />
                        </RadioGroup>
                    </FormControl>
                    </Box>
                    <Box style={{
                        marginBottom: '217px'
                    }}>
                    <div className="heading">Provider Type
                  </div>
                    <FormControl className="inputFieldLast1" fullWidth>
                        <Select
                        name="dropdown"
                        placeholder="Select one"
                        defaultValue="Hello World"
                        value={this.state.preference || ''}
                        data-test-id="stateID"
                        displayEmpty
                        MenuProps={{
                            PaperProps: {
                            sx: {
                                border: "1px solid #E6C79C",
                                borderRadius: "0px 0px 24px 24px"
                            },
                            },
                        }}
                        sx={{
                            border: "1px solid #E6C79C",
                            borderRadius: "0px 0px 24px 24px",
                            padding: "12px",
                            borderTop: "none",
                            "& fieldset": {
                            border: "none"
                            },
                            "& .MuiSelect-select": {
                            borderRadius: "16px",
                            fontFamily: "Poppins", 
                            fontSize: "16px", 
                            lineHeight: "24px", 
                            fontWeight: 400, 
                            color: "##2C2524", 
                            },
                        }} onChange={(event)=>this.handlePreferenceChange(event)} 
                           IconComponent={IconComponent}
                        >
                            <MenuItem value='' disabled>
                            Select one
                            </MenuItem>
                        {this.state.preferenceDropdown.map((item: string, index: number) => (
                            <MenuItem
                            key={index}
                            value={item}
                            sx={{
                                borderBottom: "1px solid #D6D3D1",
                                fontWeight: 400,
                                padding: "8px 12px 8px 16px",
                                height: "40px",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                lineHeight: "24px",
                                "&:hover": {
                                backgroundColor: "#e0e0e0",
                                },
                            }}
                            >
                            {item}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl> 
                    </Box>
                    
                    <button
                    disabled={!this.state.selectedRadioValue || !this.state.dropDownState || !this.state.preference}
                    className={`button-continue ${ResolveCondition(
                    !this.state.selectedRadioValue || !this.state.dropDownState || !this.state.preference,
                    "inactive",
                    "active"
                    )}`}
                    data-test-id="submitBtn"
                    type="submit"
                    onClick={()=>{}}
                >
                    See my recommendations  
                </button>
                    </Box>  
                </Box>   
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .box": {
        width: "100%",
        maxWidth: "600px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        marginTop: "32px",
        "@media screen and (max-width:750px)": {
          width: "75%"
        },
        "@media screen and (max-width:700px)": {
          width: "80%"
        },
        "@media screen and (max-width:650px)": {
          width: "80%"
        },
        "@media screen and (max-width:600px)": {
          width: "75%"
        },
        "@media screen and (max-width:500px)": {
          width: "75%"
        },
      },
      "& .button-continue": {
        padding: "16px 20px",
        border: "none",
        maxWidth: "392px",
        maxHeight: "56px",
        height: "100%",
        width: "100%",
        borderRadius: "24px",
        cursor: "pointer !important",
        background: "#E46760",
        marginBottom: "70px",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "23.48px",
        margin : 'auto'
      },
      "& .button-continue.active": {
        border: "none",
        background: "#E46760",
        cursor: "pointer",
        color: "#FFFFFF",
        fontFamily: "Raleway",
        fontWeight: "700",
        fontSize: "20px",
        padding: "16px 20px",
        marginBottom: "70px",
      },
    
      "& .button-continue.inactive": {
        border: "none",
        background: "#D6D3D1",
        cursor: "not-allowed",
        color: "#FFFFFF",
        disableCursor: true,
        fontFamily: "Raleway",
        fontWeight: "700",
        fontSize: "20px",
        padding: "16px 20px",
        marginBottom: "70px",
      },
    '& .container-elm': {
        backgroundColor: "#fff",
        height: "auto",
        minHeight: "78px",
        display: "flex",
        flexDirection: "column",
        borderLeft: "32px solid #1A4A42",
        background: '#FFFAF6'
    },
    "& .div-container": {
        marginBottom: "32px",
      },
      "& .heading": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "20px",
        lineHeight: "24px",
        textAlign: "justify",
        marginBottom: '4px'
      },
    '& .content-container': {
        display: "flex",
        flexDirection: "column",
        width: "48%",
        margin: "0 auto",
        "& .headingText, & .subText, & .bodyText": {
            textAlign: "center",
            margin: 0
        },
        "& .headingText": {
            fontFamily: "Raleway",
            fontSize: "50px",
            fontWeight: 700,
            lineHeight: "60px",
            letterSpacing: "0.5px",
            marginTop: "75px"
        },
        "& .subText": {
            color: "#2C2524",
            fontWeight: 700,
            marginTop: '40px',
            marginBottom: '24px'
        },
        '& .bodyText': {
            color: "#44403C",
            fontWeight: 400,
            margin: "0px auto",
            width: '649px',
        },
        "& .subText, & .bodyText": {
            fontFamily: "Poppins",
            fontSize: "20px",
            lineHeight: "30px"
        }
    },
    "& .header-section": {
        height: "78px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #E7E5E4",
        width: "100%",
        '& img': { paddingLeft: "3%" },
    },
    "& .logoImg": {
        width: "162px",
        height: "41.24px"
    },
    "& .headerStepper": {
        "display": "flex",
        "justifyContent": "center",
    },
    "& .inputFieldLast1 .MuiOutlinedInput-root": {

        "&:hover fieldset": {
          border: "1px solid #E6C79C",
        },
        "& fieldset": {
          border: "1px solid #E6C79C",
        },
        "&.Mui-focused fieldset": {
          border: "1px solid #E6C79C",
        },
      },
      "& .inputFieldLast1 .MuiInputBase-root.MuiOutlinedInput-root":
      {
        padding: "10px 30px",
        borderRadius: "24px",
        maxWidth: "100%",
        border: "0px solid #E6C79C",
        width: "100%",
        maxHeight: "44px",
      },
      "& .inputFieldLast1": {
        maxHeight: "72px",
        borderRadius: "24px",
        maxWidth: "100%",
        width: "100%",
        "& .MuiSelect-select" :{
            display: "flex",
          marginLeft: "-5%",
        }, "& .MuiSelect-outlined":{
            display: "flex",
          marginLeft: "-5%",
        }},
      "& .otherInputFieldLast1 .MuiInputBase-root.MuiOutlinedInput-root" :{
          maxHeight: "44px",
          maxWidth: "100%",
          border: "0px solid #E6C79C",
          width: "100%",
          borderRadius: "24px",
          padding: "10px 4px",
        },
      "& .otherInputFieldLast1" : {
          borderRadius: "24px",
          marginBottom: "9px",
          maxWidth: "100%",
          width: "100%",
          maxHeight: "72px",
        "& .MuiSelect-select" : {
          display: "flex",
          marginLeft: "-5%",
        }, "& .MuiSelect-outlined" : {
          display: "flex",
          marginLeft: "-5%",
        }},
       "& .otherInputFieldLast1 .MuiOutlinedInput-root" : {
        "&:hover fieldset" : {
          
          border: "1px solid #E6C79C"
        },
        "& fieldset" : {
          border: "1px solid #E6C79C"
        },
        "&.Mui-focused fieldset" : {
          border: "1px solid #E6C79C"
        }, },
}
);

export default CareTeam;
// Customizable Area End