// Customizable Area Start
import { createRef, RefObject } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    id: string;
    classes: Object;
    navigation: any;
}

interface S {
    toggleMenu: boolean,
    selectedMenu: string,
    button_text: string,
    showModal: boolean,
    messageText: string,
    file: any,
    isQueue: boolean
}

interface SS {
    id: string;
}

export default class ScheduledSessionController extends BlockComponent<
    Props,
    S,
    SS
> {
    getNeedHelpCallId: string = "";
    needHelpPostApiCallID: string = "";
    dropDownRef: RefObject<HTMLDivElement> | null;
    uploadRef: RefObject<HTMLInputElement> | null;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            toggleMenu: false,
            selectedMenu: '',
            button_text: 'New Chat',
            showModal: false,
            messageText: '',
            file: [],
            isQueue: false
        };
        this.dropDownRef = createRef();
        this.uploadRef = createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidUpdate() {
      document.title = `Need Help || TotalLifePlatform`;
    }

    handleSelectedData = (data: string) => {
        this.setState({
            selectedMenu: data,
            toggleMenu: false
        })
    }

    handleFile = (event: any) => {
        try {
            const file = event.target.files[0];
            if (file) {
                this.setState({ messageText: file.name });
            }
        } catch (error) {
            console.error("Error handling file input:", error);
        }
    };
    

    handleMessage = (data: string) => {
        this.setState({
            messageText: data
        })
    }
    
    async componentDidMount(): Promise<void> {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.handleCheckWaitingQueue()
        document.title = `Scheduled Session || TotalLifePlatform`;
    }

    async componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.title = `Scheduled Session || TotalLifePlatform`;
    }

    handleClickOutside = (event: MouseEvent) => {
        if (this.dropDownRef && this.dropDownRef.current && !this.dropDownRef.current.contains(event.target as Node)) {
            this.setState({ toggleMenu: false });
        }
    };

    handleCheckWaitingQueue = () => {
      this.setState({isQueue: true})

      setTimeout(()=> {
      this.setState({isQueue: false})
      }, 5000)
    }

}

// Customizable Area End