import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    listData: { progress_bar_text: string, progress_bar_stage: string }[]
    activeStep: string;
    insurancePlan: string;
}

interface SS {
    id: any;
}

export default class CheckInsuranceCoverageController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            listData: [
                { progress_bar_text: 'About You', progress_bar_stage: '1' },
                { progress_bar_text: 'Verify Insurance', progress_bar_stage: '2' },
                { progress_bar_text: 'Choose Provider', progress_bar_stage: '3' }
            ],
            activeStep: 'Verify Insurance',
            insurancePlan: "Original Medicare"
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleInsurance = (insurancePlan: string) => { this.setState({ insurancePlan: insurancePlan }) }

    goBack = () => { this.props.navigation.goBack() };
    // Customizable Area End

}