// Customizable Area Start
import { createRef, RefObject } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    id: string;
    classes: Object;
    navigation: any;
}

interface S {
    toggleMenu: boolean;
    listData: {progress_bar_text: string, progress_bar_stage: string}[]
    activeStep: string;
    dropDownState: any;
    stateDropdown: any;
    preferenceDropdown: any;
    selectedRadioValue: any;
    open: boolean;
    preference: string;
}

interface SS {
    id: string;
}

export default class NeedHelpController extends BlockComponent<
    Props,
    S,
    SS
> {
    getNeedHelpCallId: string = "";
    needHelpPostApiCallID: string = "";
    dropDownRef: RefObject<HTMLDivElement> | null;
    uploadRef: RefObject<HTMLInputElement> | null;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            toggleMenu: false,
            listData: [
                { progress_bar_text: 'About You', progress_bar_stage: '1' }, 
                { progress_bar_text: 'Verify Insurance', progress_bar_stage: '2' },
                { progress_bar_text: 'Choose Provider',  progress_bar_stage: '3'}
              ],
              activeStep: 'Verify Insurance',
              dropDownState: '',
              stateDropdown: ['India', 'Afghanistan', 'Iran', 'China'],
              preferenceDropdown: ['Therapist', 'Psychiatrist', 'Nutritionist/Dietitian', 'Medical Doctor' ],
              selectedRadioValue: '',
              open: false,
              preference: '',
        };
        this.uploadRef = createRef();
        this.dropDownRef = createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        document.title = `Pay with Cash || TotalLifePlatform`;
    }

    async componentDidUpdate() {
        document.title = `Pay with Cash || TotalLifePlatform`;
      }

      handleStateChange = (e: any) => {
        const selectedValue = e.target.value; 
        this.setState({
          dropDownState: selectedValue,
        });
      };

      handlePreferenceChange = (event: any) => {
        const preferenceValue = event.target.value; 
        this.setState({ preference: preferenceValue});
    };

    handleRadioChange = (event: { target: { value: string; }; }) => {
        this.setState({ selectedRadioValue: event.target.value });
      };
      
}

// Customizable Area End