// Customizable Area Start
import React from 'react';
import {
    Box,
    Button,
    Typography,
    styled
} from "@mui/material";
import { HeaderLogoImg, optionImage, activeOptionImage } from './assets';
import PageNotFound from "../../../components/src/PageNotFound.web";

import WellnessMembershipPlanController, {
    Props,
} from "./WellnessMembershipPlanController";
import FooterData from "../../../components/src/SignupFooter.web";
import {
    ConditionalRenderer,
    handleNavigation,
  } from "../../../blocks/utilities/src/CustomHelpers";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { phoneIcon } from '../../../blocks/email-account-registration/src/assets';

class WellnessMembershipPlan extends WellnessMembershipPlanController {
    constructor(props: Props) {
        super(props);
    }
    getTagClass = (tag: string): string => {
        if (!tag) {
            return "default";
        }
    
        switch (tag) {
            case "Most Popular":
                return "silver text-white";
            case "Best Value":
                return "bronze";
            default:
                return "gold text-white";
        }
    };
    
    handleButtonElm = (
        button_text: string,
        urlData: string,
        external_link: boolean,
        activated: boolean,
      ) => {
        if (external_link) {
            return (
              <a
                className={`buttonSecond ${activated ? "text-white gold-btn active" : ""}`}
                data-test-id={button_text}
                href={urlData}
                rel="noopener noreferrer"
                key={button_text}
              >
                {button_text}
              </a>
            );
        } else {
          return (
            <CustomButton className={`default-button silver-btn ${activated ? "text-white gold-btn active" : ""} `}
            data-test-id="choose-plan"
            onClick={() => this.handleNavigation(urlData)}
            key={button_text}
            >
                {button_text}
            </CustomButton>
          );
        }
      };

      renderDescription = (description: string, activated: boolean) => {
 
        const paragraphs = description
        .split(/\r\n/)
        .filter((p: string) => p.trim() !== '');

        return (
            <div>
                {paragraphs.map((paragraph: string, index: number) => (
            <div key={index} style={{display:"flex", marginTop: "14px"}}>
                    <CheckCircleOutlineIcon  className={`circle-icon ${activated ? 'text-white' : ''}`} />
                    <Typography
                        key={index}
                        className={activated ? "plans-description text-white" : "plans-description"}
                        dangerouslySetInnerHTML={{ __html: paragraph }}
                    />
            </div>
                ))}
            </div>
        );
    }

    renderText = (plan: any) => {
        const { title, activated, price, time_period } = plan.attributes;
    
        const marginTop = title.includes('Bronze') ? '40px' : '32px';
    
        let marginBottom = '18px'; 
        if (title.includes('Bronze')) {
            marginBottom = '85px';
        } else if (title.includes('Silver')) {
            marginBottom = '57px';
        }
    
        const formattedPrice = title.includes('Gold') ? price : `${price}.00`;
    
        return (
            <Typography
                style={{
                    marginTop,
                    marginBottom,
                }}
                className={`rate-text active-text silver-rate ${activated ? 'text-white' : ''}`}
            >
                {`$${formattedPrice} / ${time_period}`}
            </Typography>
        );
    };
    

    renderPlanCard = (plan: any, index: number) => {
        const tagClass = this.getTagClass(plan.attributes.tags);
        return (
            <Box key={index} className={plan.attributes.activated ? "card-elm active" : "card-elm"}>
                <Box className={`label-style ${tagClass}`}>{plan.attributes.tags}</Box>
                <Box className="card-details">
                    <Typography className={plan.attributes.activated ? 'heading-text text-white' : 'heading-text'}>
                        {plan.attributes.title}
                    </Typography>
                    <Typography className={plan.attributes.activated ? 'description-text active-text gold-text text-white' : 'description-text active-text silver-text'}>
                        {plan.attributes.sub_title}
                    </Typography>
                    <Box className="option-container">
                        <Box className="label-container">
                            {this.renderDescription(plan.attributes.description, plan.attributes.activated)}
                        </Box>
                    </Box>
                   {this.renderText(plan)}
                    <Box style={{ textAlign: "center" }}>
                        {plan.attributes.primary_links.map((link: { primary_button_text: string; primary_link_url: string; external_link: boolean; }, idx: number) => (
                            ConditionalRenderer(
                                Boolean(link?.primary_button_text),
                                this.handleButtonElm(link?.primary_button_text, link?.primary_link_url, link?.external_link, plan.attributes.activated),
                                ""
                            )
                        ))}
                        <Typography
                            data-test-id="full-details1"
                            onClick={() => handleNavigation(`WellnessMemebershipDetails`, this.props, plan?.id)}
                            className={`footer-text silver-footer-text ${plan.attributes.activated ? "text-white btn-active active" : ""}`}
                        >
                            {plan.attributes?.secondary_button_text}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    };

    render() {
        return (
            <StyledWrapper>
                 {this.state.message ? <PageNotFound /> : 
                 (
                <Box style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Box className="form-container">
                        <Box className="header-section">
                            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                        </Box>
                        {this.state.responseWellness.map((itm: { attributes: { title: string; description: string; sub_plans: { data: any[]; }; }; }, index: number)=>(
                                <Box key={index}>
                        <Box className="heading-text mt-50">
                        {itm.attributes.title }
                        </Box>
                        <Box className="text-body">
        <Typography className='subtitle-wellness'
        dangerouslySetInnerHTML={{__html: itm.attributes.description}}
            />
                        </Box>
                        <Box className="card-section">
                                        {itm.attributes.sub_plans?.data.map(this.renderPlanCard)}
                                    </Box>
                                </Box>
                            ))}
                        <Box className="footer">
                    <Box className="footerRight">
                    <span className="label-700-20"     
                   onClick={()=>handleNavigation('needHelp',this.props)}
                    >
                      Need Help?
                    </span>
                        <CustomButton 
                        onClick={() => handleNavigation(`needHelp`, this.props)}
                        className="callButton" data-test-id="callBTN">
                            Schedule a call
                            <img src={phoneIcon} alt="call" />
                        </CustomButton>
                    </Box>
                </Box>
                    </Box>
                </Box>
                            )
    }
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .mt-50": {
        marginTop: "49px",
        marginBottom: "16px"
    },
    "& .heading-text": {
        fontFamily: "Raleway",
        fontSize: "50px",
        fontWeight: 700,
        lineHeight: "60px",
        letterSpacing: "0.5px",
        textAlign: "center",
        color: "#1C1917",
    },
    "& .circle-icon": {
        width: '20px',
        height: '20px',
        marginRight: "9px",
        marginTop: "2px",
        color: "rgba(26, 74, 66, 1)"

      },
      "& .footer": {
        display: "flex",
        borderTop: "1px solid #E7E5E4",
        alignItems: "center",
        minHeight: "96px",
        width: '100%',
        justifyContent: 'end',
        background: 'white',
        "@media screen and (max-width:900px)": {
          display: "block",
          paddingBottom: "12px"
        },
      },
      "& .footerRight": {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        marginRight: '300px',
        "@media screen and (max-width:1500px)": {
          marginRight: '100px',
          },
        "@media screen and (max-width:1550px)": {
          marginRight: '129px',
          },
        "@media screen and (max-width:1400px)": {
          marginRight: '95px',
          },
        "@media screen and (max-width:1250px)": {
          marginRight: '64px',
          },
        "@media screen and (max-width:1200px)": {
          marginRight: '45px'
          },
        "@media screen and (max-width:1100px)": {
          marginRight: '30px'
          }, 
        "@media screen and (max-width:900px)": {
          justifyContent: "center"
          },
          "@media screen and (max-width:500px)": {
            textAlign: 'center',
            display: 'block'
          },
      },
      "& .label-700-20": {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Poppins",
        cursor: 'pointer'
      },
      "& .callButton": {
        background: "#ffffff",
        borderRadius: "16px",
        border: "2px solid #E46760",
        padding: "10px 16px 10px 16px",
        color: "#E46760",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "36px",
        letterSpacing: "1px",
        "@media screen and (max-width:900px)": {
          padding: "6px",
          },
          "@media screen and (max-width:500px)": {
            margin: "auto",
            },
      },
    "& .buttonSecond": {
        padding: "16.5px 0",
        display: "flex",
        justifyContent: "center",
        margin: "20px auto 0",
        cursor: "pointer",
        textDecoration: "none",
        width: "260px",
        paddingLeft: "20px",
        paddingRight: "20px",
        borderRadius: "24px",
        border: "2px solid #E46760 !important",
        fontFamily: "Inter,sans-serif",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        color: "#E46760",
        backgroundColor: "transparent"
      },
    "& .active": {
        backgroundColor: "#1A4A42 !important",
        border: "none"
    },
    "& .card-section": {
        display: "flex",
        gap: "24px",
        marginBottom: "122px",
        marginTop: "60px",
        "@media screen and (max-width: 1200px)": {
            display: "block",
    },
    },
    ".plans-description p": {
        margin: "0px"
      },
    "& .text-body": {
        maxWidth: "601px",
        display: "inline",
        width: 'auto',
    },
    "& .text-body p": {
        margin: '0 auto',
        padding: 0
    },
    "& .subtitle-wellness": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        textAlign: "center",
        color: "#1C1917",
        width: '601px',
        margin: 'auto',
    },
    "& .option-container": {
        display: "flex",
        flexDirection: "column",
        gap: "14px"
    },
    "& .label-container": {
        display: "flex",
        gap: "10.33px",
        alignItems: "center"
    },
    "& .card-elm": {
        maxWidth: "336px",
        backgroundColor: "#fff",
        borderRadius: "20px",
        border: "0.5px dashed #203447",
        paddingBottom: "18px",
        display: "flex",
        flexDirection: "column",
        height: "549px",
        flex: 1,
        "@media screen and (max-width: 1200px)": {
                maxWidth: "100%",
                padding: '3px 83px',
                margin: '35px 0px',
        },
        "& .details-container": {
            padding: "18px 27px",
        },
        "& .label-style": {
            display: "flex",
            width: "108px",
            height: "28px",
            marginTop: "16px",
            borderRadius: "0px 6px 6px 0px",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "24px",
            paddingRight: "13px",
            paddingLeft: "23px"
        },
        "& .bronze": {
            backgroundColor: "rgba(230, 199, 156, 1)",
            color: "black"
        },
        "& .silver": {
            backgroundColor: "#E46760",
            color: "#fff"
        },
        "& .gold": {
            backgroundColor: "rgb(148, 19, 80)"
        },
        "& .gold-btn": {
            backgroundColor: "#E46760 !important",
        },
        "& .footer-text": {
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "italic",
            cursor: "pointer",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#807C7C",
            marginTop: "24px",
            textDecoration: "underline",
            "&.active-data": {
                color: "#D1DBD9"
            }
        },
        "& .rate-text": {
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "30px",
            // marginTop: "32px",
            // marginBottom: "85px"
        },
        "& .heading-text": {
            width:"282.74px",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "36px",
            color: "#1C1917",
            textAlign: "left"
        },
        "& .description-text": {
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginTop: "21px",
            marginBottom: "33px",
            paddingLeft: "3px"
        },
        "& .card-details": {
            paddingLeft: "24px",
            paddingRight: "24px",
            marginTop: "18px"
        }
    },
    "& .active-text": {
        background: "linear-gradient(0deg, #2C2524, #2C2524), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    "& .text-white": {
        color: "#fff !important",
        WebkitTextFillColor: "#fff !important"
    },
    "& .form-container": {
        backgroundColor: "#e8edec",
        height: "auto",
        minHeight: "78px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        borderLeft: "32px solid #1A4A42"
    },
    "& .header-section": {
        height: "78px",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #E7E5E4",
        width: "100%",
        borderTopRightRadius: "32px",
        '& img': { paddingLeft: "112px" },
    },
    "& .logoImg": {
        width: "162px",
        height: "41.24px"
    },
    "& .footer-section": {
        backgroundColor: "#fff",
        borderBottomRightRadius: "32px",
        "& .left-section": {
            display: "none !important"
        },
        "& .right-section": {
            marginLeft: "auto"
        }
    },
    "& .email-container, & .mobilenumber-container, & .landline-container": {
        width: "100%"
    },

    '& .firstname-container, & .lastname-container, & .email-container, & .mobilenumber-container': {
        marginTop: "4px"
    }
});

const CustomButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    "&.default-button": {
        width: "288px",
        height: "56px",
        paddingLeft: "20px",
        paddingRight: "20px",
        borderRadius: "24px",
        border: "2px solid #E46760 !important",
        fontFamily: "Inter,sans-serif",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        color: "#E46760",
        backgroundColor: "transparent"
    },
    "&.active": {
        backgroundColor: "#E46760 !important",
        fontFamily: "Raleway !important",
        fontSize: "20px",
        lineHeight: "23.48px",
        letterSpacing: "1px",
        color: "#FFFAF6"
    },
    '&.Mui-disabled.submit-button': {
        backgroundColor: '#D6D3D1',
        color: '#78716C',
        cursor: 'not-allowed',
        boxShadow: 'none',
        pointerEvents: 'none',
    },
    '&:hover': {
        backgroundColor: 'transparent'
    }
}));

export default WellnessMembershipPlan;
// Customizable Area End