// Customizable Area Start
import React from 'react';
import {
    styled,
    Typography,
    Box,
    Button
} from "@mui/material";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { phoneIcon, emergency} from '../../blocks/email-account-registration/src/assets';
import { handleNavigation } from '../../blocks/utilities/src/CustomHelpers';

interface Props {
    data?: Object
}

interface S {
    activeStep?: Object
}

interface SS {
    id: string;
}

class SignupFooter extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyledWrapper>
                <Box className="footer">
                    <p className="emergencyIcon"><img src={emergency} alt="emergency" />&nbsp; 
                   If this is an emergency <span style={{
                      color: '#D97706',
                      marginLeft: '6px',
                      textDecoration: 'underline'
                    }}>please call 911</span>
                    </p>
                    <Box className="footerRight">
                    <span className="label-700-20"     
                   onClick={()=>handleNavigation('NeedHelp',this.props)}
                    >
                      Need Help?
                    </span>
                        <CustomButton className="callButton" data-test-id="callBTN">
                            Schedule a call
                            <img src={phoneIcon} alt="call" />
                        </CustomButton>
                    </Box>
                </Box>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    "& .footer-section": {
        height: "96px",
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #E7E5E4",
        justifyContent: "space-between",
        width: "100%",
        "& .emergency-call": {
            textDecoration: "underline",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#D97706",
            cursor: "pointer",
            "@media screen and (max-width:1000px)":{
                marginRight:"308px"
            },
            "@media screen and (max-width:750px)":{
                marginRight:"450px"
            },
            "@media screen and (max-width:700px)":{
                marginRight:"457px"
            },
            "@media screen and (max-width:500px)":{
                marginRight:"457px",
                marginLeft:"-12px"
            }
        },
        "& .left-section, & .right-section": {
            display: "flex",
            gap: "12px",
            alignItems: "center",
        },
        '& .left-section': {
            marginLeft: "109px",
            "@media screen and (max-width:1100px)":{
                marginLeft: "32px",
            },
            "@media screen and (max-width:1000px)":{
                marginLeft: "40px",
            },
            "@media screen and (max-width:700px)":{
                marginLeft: "8px",
                marginRight:"116px"
            },
            "@media screen and (max-width:650px)":{
                marginLeft: "27px",
                marginRight:"44px"
            },
            "@media screen and (max-width:600px)":{
                marginLeft: "2px",
                marginRight:"46px"
            },
            "@media screen and (max-width:550px)":{
                marginLeft: "6px",
                marginRight:"-17px"
            },
            "@media screen and (max-width:500px)":{
                marginLeft: "1px",
            },
        },
        '& .right-section': {
            marginRight: "144px",
            "@media screen and (max-width:1100px)": {
                marginRight:"35px",
              },
            "@media screen and (max-width:1000px)":{
                marginRight:"47px"
            },
            "@media screen and (max-width:850px)":{
                marginRight:"47px",
                marginLeft:"-85px"
            },
            "@media screen and (max-width:800px)":{
                marginLeft:"-131px"
            },
            "@media screen and (max-width:750px)":{
                marginLeft:"-344px"
            },
            "@media screen and (max-width:700px)":{
                width:"108px",
                marginLeft:"-327px"
            },
            "@media screen and (max-width:500px)":{
                width:"108px",
                marginLeft:"-334px"
            }
        },
        "& .need-help": {
            fontWeight: 700,
            "@media screen and (max-width:700px)":{
                marginLeft:"-96px"
        },
        "@media screen and (max-width:500px)":{
            marginLeft:"-93px"
        }
        },
    },
    "& .default-text": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        textAlign: "left"
        },
        "& .footer": {
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid #E7E5E4",
            alignItems: "center",
            minHeight: "96px",
            "@media screen and (max-width:900px)": {
              display: "block",
              paddingBottom: "12px"
            },
          },
          "& .emergencyIcon": {
      display: "flex",
      alignItems: "center",
      fontFamily: "Poppins",
      fontWeight: "400",
      fontSize: "20px",
      marginRight: '459px',
      marginLeft: '141px',
      "@media screen and (max-width: 1550px)": {
        marginRight: '423px',
        marginLeft: '129px'
      },
      "@media screen and (max-width: 1500px)": {
        marginRight: '338px',
        marginLeft: '100px'
      },
      "@media screen and (max-width: 1400px)": {
        marginRight: '242px',
        marginLeft: '95px'
      },
      "@media screen and (max-width: 1250px)": {
        marginRight: '211px',
        marginLeft: '64px'
      },
      "@media screen and (max-width: 1200px)": {
        marginRight: '100px',
        marginLeft: '45px'
      },
      "@media screen and (max-width: 1100px)": {
        marginRight: '80px',
        marginLeft: '30px'
      },
      "@media screen and (max-width: 950px)": {
        marginRight: '35px',
        marginLeft: '15px'
      },
      "@media screen and (max-width: 900px)": {
        justifyContent: "center"
      },
      "@media screen and (max-width:500px)": {
        textAlign: 'center',
        display: "block"
      },
    },
    "& .footerRight": {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        marginRight: '141px',
        "@media screen and (max-width:1500px)": {
          marginRight: '100px',
          },
        "@media screen and (max-width:1550px)": {
          marginRight: '129px',
          },
        "@media screen and (max-width:1400px)": {
          marginRight: '95px',
          },
        "@media screen and (max-width:1250px)": {
          marginRight: '64px',
          },
        "@media screen and (max-width:1200px)": {
          marginRight: '45px'
          },
        "@media screen and (max-width:1100px)": {
          marginRight: '30px'
          }, 
        "@media screen and (max-width:900px)": {
          justifyContent: "center"
          },
          "@media screen and (max-width:500px)": {
            textAlign: 'center',
            display: 'block'
          },
      },
      "& .label-700-20": {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Poppins",
        cursor: 'pointer'
      },
      "& .callButton": {
        background: "#ffffff",
        borderRadius: "16px",
        border: "2px solid #E46760",
        padding: "10px 16px 10px 16px",
        color: "#E46760",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "36px",
        letterSpacing: "1px",
        "@media screen and (max-width:900px)": {
          padding: "6px",
          },
          "@media screen and (max-width:500px)": {
            margin: "auto",
            },
      },
});


const CustomButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    "&.call-button": {
        height: "44px",
        gap: "8px",
        borderRadius: "16px",
        border: "2px solid #E46760",
        color: "#E46760",
        padding: "0 16px",
        "@media screen and (max-width:700px)":{
           marginLeft:"-49px",
           width:"158px"
        },
        "@media screen and (max-width:500px)":{
            padding: "0 10px",
            marginRight:"11px"
        },
    },
    "&.call-button::hover": {
        border: "2px solid #E46760",
        color: "#E46760"
    },
}));

export default SignupFooter;
// Customizable Area End