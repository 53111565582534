import React from "react";
import { Dialog, DialogTitle, DialogContent,Box, DialogActions, Button, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import {regularIcon} from '../../blocks/customform/src/assets'

interface SupplementModalProps {
    open: boolean;
    onClose: () => void;
    handleClickYes: () => void;
    handleCheckMyCoverage: () => void;
  }

export default function SupplementModal(props: SupplementModalProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}
    PaperProps={{ sx: { width: '600px', borderRadius: '24px', 
      justifyContent: 'center', alignItems: 'center'
     } }} 
  >
    <DialogTitle style={{padding: 0}} >
      <Typography style={webStyles.title} align="center">Do you have a supplemental insurance?
      </Typography>
    </DialogTitle>
    <DialogContent style={{ textAlign: 'center', padding:0 , width: '100%'}}>
      <img src={regularIcon} alt="Image" width={104} height={120} style={{marginBottom: '40px'}}/>
     <div style={webStyles.divider}></div>
     <Box style={webStyles.buttonContainer}>
      <button style={webStyles.button} onClick={props.handleClickYes}>
        Yes
      </button>
      <button style={webStyles.secondaryButton}  onClick={props.handleCheckMyCoverage}>
        No, check my coverage
      </button>
     </Box>
    </DialogContent>
  </Dialog>
  )
}

const webStyles = {
  root: {
    position: "absolute" as const,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute" as const,
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  title: {
    fontSize: '29px',
    padding: '48px 16px 64px 16px',
    fontFamily: 'Poppins',
    fontWeight: 500
  },
  box: {
    width: '600px'
  },
  divider: {
    height: '2px',
    backgroundColor: '#ccc',
    margin: '20px 0',
    width: '100%'
  },
  buttonContainer: {
    marginTop: '24px',
    marginBottom: '32px'
  },
  button: {
    margin: "auto",
    cursor: 'pointer',
    marginBottom: '24px',
    display: "flex",
    justifyContent: "center",
    width: "392px",
    padding: '16.5px',
    color: '#FFFFFF',
    borderRadius: "24px",
    border: 'none',
    background: "#E46760",
    fontSize: "20px",
    fontFamily: "Raleway",
    fontWeight: 700,
    alignItems: "center",
    letterSpacing: '1px'
},
secondaryButton: {
    margin: "auto",
    marginBottom: '12px',
    "display": "flex",
    "justifyContent": "center",
    width: "392px",
    padding: '16px',
    color: '#E46760',
    borderRadius: "24px",
    border: '2px solid #E46760',
    background: "none",
    fontSize: "20px",
    fontFamily: "Raleway",
    fontWeight: 700,
    alignItems: "center",
    letterSpacing: '1px',
    cursor: 'pointer'
},
};